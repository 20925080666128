import React, { createContext, useState, useEffect, useContext } from 'react';
import { userApi } from '../services/api';
import { message } from 'antd';

// 创建认证上下文
const AuthContext = createContext();

// 认证上下文提供者组件
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 从本地存储加载用户信息
  useEffect(() => {
    const loadUser = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        const token = localStorage.getItem('token');

        if (storedUser && token) {
          setUser(JSON.parse(storedUser));
          // 验证token有效性
          try {
            const response = await userApi.getCurrentUser();
            setUser(response.data.data.user);
            localStorage.setItem('user', JSON.stringify(response.data.data.user));
          } catch (err) {
            // Token无效，清除本地存储
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setUser(null);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadUser();
  }, []);

  // 发送验证码
  const sendVerificationCode = async (phone) => {
    try {
      setLoading(true);
      const response = await userApi.sendVerificationCode({ phone });
      return response.data;
    } catch (err) {
      setError(err.response?.data?.message || '发送验证码失败');
      message.error(err.response?.data?.message || '发送验证码失败');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // 用户登录/注册（通过手机验证码）
  const login = async (credentials) => {
    try {
      setLoading(true);
      const response = await userApi.login(credentials);
      const { token, user: loggedInUser } = response.data.data;
      
      // 保存token和用户信息到本地存储
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(loggedInUser));
      
      setUser(loggedInUser);
      message.success('登录成功！');
      return true;
    } catch (err) {
      setError(err.response?.data?.message || '登录失败，请检查手机号和验证码');
      message.error(err.response?.data?.message || '登录失败，请检查手机号和验证码');
      return false;
    } finally {
      setLoading(false);
    }
  };

  // 用户登出
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    message.success('已成功登出');
  };

  // 刷新用户信息
  const refreshUserInfo = async () => {
    try {
      setLoading(true);
      const response = await userApi.getCurrentUser();
      const updatedUser = response.data.data.user;
      
      localStorage.setItem('user', JSON.stringify(updatedUser));
      setUser(updatedUser);
      return updatedUser;
    } catch (err) {
      setError(err.response?.data?.message || '获取用户信息失败');
      return null;
    } finally {
      setLoading(false);
    }
  };

  // 提供上下文值
  const value = {
    user,
    loading,
    error,
    sendVerificationCode,
    login,
    logout,
    refreshUserInfo,
    isAuthenticated: !!user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// 自定义钩子，用于访问认证上下文
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth必须在AuthProvider内部使用');
  }
  return context;
};

export default AuthContext; 