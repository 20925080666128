import React, { useState, useEffect, useRef } from 'react';
import { 
  Typography, 
  Upload, 
  Button, 
  Input, 
  Space, 
  message, 
  Spin, 
  Card,
  Alert} from 'antd';
import { 
  UploadOutlined, 
  FileTextOutlined, 
  SendOutlined,
  CopyOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { ApiKeyInput } from './CommonComponents';

const { Title, Text } = Typography;
const { TextArea } = Input;

// 定义卡片和内容区域的固定高度
const CARD_HEIGHT = 'calc(100vh - 370px)';
const CONTENT_HEIGHT = 'calc(100vh - 480px)';

const ResumeParser = () => {
  const [resumeText, setResumeText] = useState('');
  const [jsonResult, setJsonResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [apiKey, setApiKey] = useState('');
  const [inputType, setInputType] = useState('text'); // 'text' 或 'file'
  const [, setContainerHeight] = useState('calc(100vh - 200px)');
  
  const containerRef = useRef(null);

  // 创建一个可重用的Tab切换组件
  const TabSwitch = ({ activeKey, options, onChange }) => {
    return (
      <div className="tab-switch" style={{ display: 'flex', borderRadius: '4px', overflow: 'hidden', border: '1px solid #f0f0f0' }}>
        {options.map(option => (
          <div 
            key={option.key}
            className={`tab-item ${activeKey === option.key ? 'active' : ''}`}
            onClick={() => onChange(option.key)}
            style={{ 
              padding: '4px 12px', 
              cursor: 'pointer', 
              backgroundColor: activeKey === option.key ? '#f0f0f0' : 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '13px',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal'
            }}
          >
            <span>{option.label}</span>
          </div>
        ))}
      </div>
    );
  };

  // 监听窗口大小变化，调整容器高度
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const viewportHeight = window.innerHeight;
        const containerTop = containerRef.current.getBoundingClientRect().top;
        // 增加底部边距到60px，确保不会超出屏幕
        const newHeight = viewportHeight - containerTop - 60;
        setContainerHeight(`${Math.max(250, newHeight)}px`);
      }
    };

    // 初始化高度
    updateHeight();

    // 监听窗口大小变化
    window.addEventListener('resize', updateHeight);
    
    // 清理函数
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  // 处理文本输入变化
  const handleTextChange = (e) => {
    setResumeText(e.target.value);
  };

  // 处理文件上传
  const props = {
    name: 'resumeFile',
    multiple: false,
    maxCount: 1,
    fileList: fileList,
    beforeUpload: (file) => {
      // 检查文件类型
      const isValidType = file.type === 'application/pdf' || 
                          file.type === 'application/msword' || 
                          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          file.type === 'text/plain';
      if (!isValidType) {
        message.error('只支持 PDF、Word 或 TXT 文件!');
        return Upload.LIST_IGNORE;
      }
      
      // 检查文件大小，限制为10MB
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error('文件必须小于10MB!');
        return Upload.LIST_IGNORE;
      }
      
      // 保存文件到状态
      setFileList([file]);
      return false; // 阻止自动上传
    },
    onRemove: () => {
      setFileList([]);
      return true;
    }
  };

  // 处理Tab切换
  const handleTabChange = (activeKey) => {
    setInputType(activeKey);
    // 切换时清空另一种输入方式的内容
    if (activeKey === 'text') {
      setFileList([]);
    } else {
      setResumeText('');
    }
  };

  // 提交解析请求
  const handleSubmit = async () => {
    if (!resumeText && fileList.length === 0) {
      message.warning('请输入简历文本或上传简历文件');
      return;
    }

    if (!apiKey) {
      message.warning('请输入API Key');
      return;
    }

    setLoading(true);
    setError(null);
    setJsonResult(null);

    try {
      let response;
      
      if (fileList.length > 0) {
        // 如果有文件，使用FormData上传
        const formData = new FormData();
        formData.append('resumeFile', fileList[0]);
        
        response = await axios.post('/api/v1/resumes/convert-text', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-KEY': apiKey,
            'Authorization': `Bearer ${apiKey}`
          },
        });
      } else {
        // 否则发送文本
        response = await axios.post('/api/v1/resumes/convert-text', {
          resumeText: resumeText
        }, {
          headers: {
            'X-API-KEY': apiKey,
            'Authorization': `Bearer ${apiKey}`
          }
        });
      }

      if (response.data.success) {
        setJsonResult(response.data.data.jsonResume);
        message.success('简历解析成功');
      } else {
        setError(response.data.message || '解析失败，请稍后重试');
      }
    } catch (err) {
      setError(err.response?.data?.message || '服务器错误，请稍后重试');
      message.error('简历解析失败');
    } finally {
      setLoading(false);
    }
  };

  // 复制JSON结果到剪贴板
  const copyToClipboard = () => {
    if (jsonResult) {
      navigator.clipboard.writeText(JSON.stringify(jsonResult, null, 2))
        .then(() => {
          message.success('已复制到剪贴板');
        })
        .catch(() => {
          message.error('复制失败，请手动复制');
        });
    }
  };

  // 清空所有内容
  const clearAll = () => {
    setResumeText('');
    setJsonResult(null);
    setError(null);
    setFileList([]);
  };

  // 格式化JSON并添加语法高亮
  const formatJsonWithSyntaxHighlighting = (json) => {
    if (!json) return '';
    
    const jsonString = JSON.stringify(json, null, 2);
    
    // 替换键名（带引号的部分）
    const withKeyHighlighting = jsonString.replace(/"([^"]+)":/g, '<span style="color: #9cdcfe;">"$1"</span>:');
    
    // 替换字符串值（键值对中的值部分）
    const withValueHighlighting = withKeyHighlighting.replace(/: "([^"]+)"/g, ': <span style="color: #ce9178;">"$1"</span>');
    
    // 替换数字
    const withNumberHighlighting = withValueHighlighting.replace(/: (\d+)(,?)/g, ': <span style="color: #b5cea8;">$1</span>$2');
    
    // 替换布尔值和null
    const withBooleanHighlighting = withNumberHighlighting
      .replace(/: (true|false)(,?)/g, ': <span style="color: #569cd6;">$1</span>$2')
      .replace(/: (null)(,?)/g, ': <span style="color: #569cd6;">$1</span>$2');
    
    // 添加行号和缩进高亮
    const lines = withBooleanHighlighting.split('\n');
    const withLineNumbers = lines.map((line, index) => {
      // 计算缩进级别（每两个空格为一级）
      // 为缩进添加淡色背景，增强可读性
      const indentedLine = line.replace(/^(\s*)/, `<span style="opacity: 0.5;">$1</span>`);
      
      // 为大括号和中括号添加特殊颜色
      const bracketHighlighted = indentedLine
        .replace(/({|}|\[|])(,?)$/g, '<span style="color: #d4d4d4;">$1</span>$2')
        .replace(/^(\s*)<span style="opacity: 0.5;">(\s*)<\/span>({|}|\[|])(,?)$/g, 
                 '$1<span style="opacity: 0.5;">$2</span><span style="color: #d4d4d4;">$3</span>$4');
      
      return `<div class="code-line" style="display: flex;">
                <span class="line-number" style="color: #858585; min-width: 40px; text-align: right; padding-right: 12px; user-select: none;">${index + 1}</span>
                <span class="line-content">${bracketHighlighted}</span>
              </div>`;
    }).join('');
    
    return <div dangerouslySetInnerHTML={{ __html: withLineNumbers }} />;
  };

  return (
    <div style={{ 
      padding: '10px 20px 30px', 
      height: 'auto', 
      overflow: 'visible',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
    }} ref={containerRef}>
      <Title level={3}>简历解析</Title>
      <Text type="secondary" style={{ marginBottom: '15px', display: 'block' }}>
        将简历文本或文件解析为标准的JSON Resume格式，支持PDF、Word和纯文本文件。
      </Text>

      {/* 添加全局滚动条样式和字体样式 */}
      <style>
        {`
          /* 全局字体样式 */
          .resume-parser-container * {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          }
          
          /* 自定义滚动条样式 - Webkit浏览器 */
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          
          ::-webkit-scrollbar-track {
            background: #f5f5f5;
            border-radius: 4px;
          }
          
          ::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 4px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: #bfbfbf;
          }
          
          /* JSON区域的暗色滚动条 */
          .json-view::-webkit-scrollbar-track {
            background: #1e1e1e;
          }
          
          .json-view::-webkit-scrollbar-thumb {
            background: #6b6b6b;
          }
          
          .json-view::-webkit-scrollbar-thumb:hover {
            background: #8c8c8c;
          }

          /* 代码行样式 */
          .code-line {
            padding: 1px 0;
          }
          
          .code-line:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          
          /* 行号样式 */
          .line-number {
            border-right: 1px solid #333;
            margin-right: 10px;
          }
          
          /* 代码内容样式 */
          .line-content {
            flex: 1;
          }
          
          /* 折叠/展开功能的样式 */
          .collapsible-bracket {
            cursor: pointer;
          }
          
          .collapsible-bracket:hover {
            color: #fff !important;
          }
        `}
      </style>

      {/* API Key输入 */}
      <ApiKeyInput apiKey={apiKey} setApiKey={setApiKey} />

      <div className="resume-parser-container" style={{ display: 'flex', gap: '15px', marginBottom: '20px' }}>
        {/* 左侧输入区域 */}
        <div style={{ flex: 1 }}>
          <Card 
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>输入</span>
                <TabSwitch 
                  activeKey={inputType} 
                  options={[
                    { key: 'text', label: '文本粘贴' },
                    { key: 'file', label: '文件上传' }
                  ]} 
                  onChange={handleTabChange} 
                />
              </div>
            } 
            bordered={false} 
            style={{ 
              height: CARD_HEIGHT, 
              display: 'flex', 
              flexDirection: 'column', 
              overflow: 'hidden'
            }}
            bodyStyle={{ 
              flex: 1, 
              overflow: 'auto', 
              paddingBottom: '25px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {inputType === 'text' ? (
              <TextArea
                placeholder="在此粘贴简历文本..."
                value={resumeText}
                onChange={handleTextChange}
                style={{ 
                  fontSize: '14px', 
                  height: CONTENT_HEIGHT,
                  resize: 'none',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#d9d9d9 #f5f5f5',
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
                }}
              />
            ) : (
              <div style={{ 
                padding: '20px 0', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: CONTENT_HEIGHT,
                backgroundColor: '#fafafa',
                borderRadius: '8px',
                border: '1px dashed #d9d9d9'
              }}>
                <div style={{ 
                  textAlign: 'center', 
                  width: '100%', 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px'
                }}>
                  <div style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    backgroundColor: '#f0f5ff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '8px'
                  }}>
                    <UploadOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                  </div>
                  
                  <div>
                    <Text style={{ fontSize: '16px', display: 'block', marginBottom: '8px' }}>
                      拖拽文件到此处或
                    </Text>
                    
                    <Upload
                      {...props}
                      style={{ marginBottom: '20px' }}
                    >
                      <Button type="primary" size="large" style={{ 
                        borderRadius: '6px', 
                        height: '44px',
                        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)'
                      }}>
                        选择文件上传
                      </Button>
                    </Upload>
                  </div>
                  
                  <div style={{ 
                    marginTop: '16px', 
                    backgroundColor: 'white', 
                    padding: '12px 16px', 
                    borderRadius: '6px',
                    border: '1px solid #f0f0f0',
                    maxWidth: '400px'
                  }}>
                    <Text type="secondary" style={{ fontSize: '13px', display: 'block', textAlign: 'center' }}>
                      支持 PDF、Word、TXT 格式的简历文件，大小不超过10MB
                    </Text>
                  </div>
                </div>
              </div>
            )}

            <div style={{ 
              marginTop: '20px', 
              textAlign: 'right',
              paddingBottom: '5px',
              height: '40px',
              minHeight: '40px'
            }}>
              <Space size="middle">
                <Button onClick={clearAll}>清空</Button>
                <Button 
                  type="primary" 
                  icon={<SendOutlined />} 
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={(inputType === 'text' && !resumeText) || (inputType === 'file' && fileList.length === 0)}
                >
                  解析简历
                </Button>
              </Space>
            </div>
          </Card>
        </div>

        {/* 右侧结果区域 */}
        <div style={{ flex: 1 }}>
          <Card 
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>解析结果</span>
                  {jsonResult && (
                    <Button 
                      type="text" 
                      icon={<CopyOutlined />} 
                      onClick={copyToClipboard}
                      style={{ marginLeft: '5px', padding: '0 4px' }}
                      title="复制JSON"
                    />
                  )}
                </div>
              </div>
            }
            bordered={false}
            style={{ 
              height: CARD_HEIGHT, 
              display: 'flex', 
              flexDirection: 'column', 
              overflow: 'hidden'
            }}
            bodyStyle={{ 
              flex: 1, 
              overflow: 'auto', 
              paddingBottom: '25px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loading ? (
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: CONTENT_HEIGHT,
                textAlign: 'center',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
              }}>
                <div style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: '#f0f5ff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '16px'
                }}>
                  <Spin size="large" />
                </div>
                <p style={{ 
                  marginTop: '16px', 
                  fontSize: '16px',
                  color: '#1890ff'
                }}>正在解析简历，请稍候...</p>
              </div>
            ) : error ? (
              <Alert
                message="解析失败"
                description={error}
                type="error"
                showIcon
                style={{
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
                }}
              />
            ) : jsonResult ? (
              <div 
                className="json-view"
                style={{ 
                  height: CONTENT_HEIGHT, 
                  overflow: 'auto', 
                  backgroundColor: '#1e1e1e', 
                  borderRadius: '4px', 
                  padding: '8px',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#6b6b6b #1e1e1e'
                }}
              >
                <pre style={{ 
                  whiteSpace: 'pre-wrap', 
                  wordBreak: 'break-all', 
                  margin: 0, 
                  fontFamily: 'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
                  fontSize: '14px',
                  color: '#d4d4d4',
                  lineHeight: '1.5'
                }}>
                  {formatJsonWithSyntaxHighlighting(jsonResult)}
                </pre>
              </div>
            ) : (
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: CONTENT_HEIGHT,
                textAlign: 'center',
                color: '#999',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
              }}>
                <div style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: '#f5f5f5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '16px'
                }}>
                  <FileTextOutlined style={{ fontSize: '36px', color: '#bfbfbf' }} />
                </div>
                <p style={{ 
                  fontSize: '16px',
                  marginTop: '16px'
                }}>解析结果将显示在这里</p>
                <p style={{ 
                  fontSize: '14px',
                  color: '#bfbfbf',
                  maxWidth: '300px',
                  marginTop: '8px'
                }}>
                  请在左侧输入简历文本或上传简历文件，然后点击"解析简历"按钮
                </p>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ResumeParser;
