/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// 定义主题色
const themeColor = '#1a1a1a';
const errorColor = '#ff4d4f';

const Login = () => {
  const { login, sendVerificationCode } = useAuth();
  const navigate = useNavigate();
  
  // 使用 useState 管理状态
  const [phone, setPhone] = useState(() => {
    // 尝试从localStorage恢复手机号
    return localStorage.getItem('lastPhoneNumber') || '';
  });
  const [code, setCode] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  
  // 使用 useRef 引用 DOM 元素
  const codeInputRef = useRef(null);
  const verificationContainerRef = useRef(null);
  const phoneRef = useRef(phone); // 添加手机号的ref备份
  
  // 当phone状态更新时，同步更新phoneRef
  useEffect(() => {
    phoneRef.current = phone;
  }, [phone]);
  
  // 验证手机号格式
  const validatePhone = (value) => {
    if (!value) {
      return '请输入手机号!';
    }
    if (!/^1[3-9]\d{9}$/.test(value)) {
      return '请输入有效的手机号!';
    }
    return '';
  };
  
  // 处理手机号输入
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    
    // 保存到localStorage
    if (value) {
      localStorage.setItem('lastPhoneNumber', value);
    }
    
    // 如果有错误，实时验证
    if (phoneError) {
      setPhoneError(validatePhone(value));
    }
  };
  
  // 处理验证码输入
  const handleCodeChange = (e) => {
    const value = e.target.value;
    setCode(value);
    
    // 如果有错误，清除错误
    if (codeError) {
      setCodeError('');
    }
  };
  
  // 触发抖动效果
  const triggerShake = () => {
    if (verificationContainerRef.current) {
      verificationContainerRef.current.classList.add('shake-animation');
      setTimeout(() => {
        if (verificationContainerRef.current) {
          verificationContainerRef.current.classList.remove('shake-animation');
        }
      }, 500);
    }
  };
  
  // 发送验证码
  const handleSendCode = async (e) => {
    // 阻止可能的默认行为
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    // 验证手机号
    const error = validatePhone(phone);
    if (error) {
      setPhoneError(error);
      return;
    }
    
    try {
      setIsLoading(true);
      
      // 调用发送验证码API
      const result = await sendVerificationCode(phone);
      
      if (result && result.success) {
        message.success('验证码已发送');
        
        // 开始倒计时
        setCountdown(60);
      }
    } catch (error) {
      message.error('发送验证码失败，请稍后重试');
    } finally {
      setIsLoading(false);
    }
  };
  
  // 提交登录
  const handleSubmit = async (e) => {
    // 阻止表单默认提交行为，防止页面刷新
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    // 验证手机号
    const phoneErrorMsg = validatePhone(phone);
    if (phoneErrorMsg) {
      setPhoneError(phoneErrorMsg);
      return;
    }
    
    // 验证验证码
    if (!code) {
      setCodeError('请输入验证码!');
      return;
    }
    
    // 保存当前手机号，以防在异步操作中丢失
    const currentPhone = phone;
    
    try {
      setIsLoading(true);
      
      // 调用登录API
      const success = await login({ phone: currentPhone, code });
      
      if (success) {
        navigate('/playground');
      } else {
        // 验证码错误处理
        setCodeError('error'); // 只用于触发输入框变红，不会显示文字
        
        // 触发抖动效果
        triggerShake();
        
        // 显示轻量级提示
        message.error('验证码错误');
        
        // 创建一个新的函数来处理验证码清空，避免状态更新问题
        const clearCodeOnly = () => {
          // 只清空验证码输入框
          setCode('');
          
          // 确保手机号不变
          if (phone !== currentPhone) {
            setPhone(currentPhone);
          }
        };
        
        // 使用setTimeout确保在React状态更新周期之外执行
        setTimeout(clearCodeOnly, 0);
      }
    } catch (error) {
      message.error('登录失败，请稍后重试');
      
      // 确保手机号不被清空
      if (!phone && currentPhone) {
        setPhone(currentPhone);
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  // 处理倒计时
  useEffect(() => {
    let timer = null;
    
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [countdown]);
  
  // 添加全局错误处理，确保手机号不会丢失
  useEffect(() => {
    // 定期检查手机号是否存在
    const checkInterval = setInterval(() => {
      const currentPhone = phone;
      const savedPhone = localStorage.getItem('lastPhoneNumber');
      
      // 如果当前手机号为空但localStorage中有值，则恢复
      if (!currentPhone && savedPhone) {
        setPhone(savedPhone);
      }
    }, 2000); // 每2秒检查一次
    
    return () => {
      clearInterval(checkInterval);
    };
  }, [phone]);
  
  // 添加键盘事件处理，防止Enter键触发表单提交
  useEffect(() => {
    const handleKeyDown = (e) => {
      // 如果按下Enter键但不是在表单提交按钮上，阻止默认行为
      if (e.key === 'Enter' && e.target.type !== 'submit') {
        e.preventDefault();
      }
    };
    
    // 添加全局键盘事件监听
    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      // 清理事件监听
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  return (
    <div className="login-container">
      <div className="title-wrapper">
        <h1 className="login-title">和 Nova 一起创造</h1>
      </div>
      <div className="login-form">
        {/* 使用表单元素并添加onSubmit事件处理 */}
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <div className="phone-input-container">
              <div className="country-code">+86</div>
              <input
                type="tel" // 使用tel类型更适合手机号
                inputMode="numeric" // 在移动设备上显示数字键盘
                placeholder="电话号码"
                value={phone}
                onChange={handlePhoneChange}
                className={`phone-input ${phoneError ? 'input-error' : ''}`}
                autoComplete="tel" // 添加自动完成属性
              />
            </div>
            {phoneError && <div className="error-message">{phoneError}</div>}
          </div>
          
          <div className="form-group">
            <div className="verification-container" ref={verificationContainerRef}>
              <div className="code-input-container">
                <SafetyOutlined className="code-icon" />
                <input
                  ref={codeInputRef}
                  type="text"
                  inputMode="numeric" // 在移动设备上显示数字键盘
                  placeholder="验证码"
                  value={code}
                  onChange={handleCodeChange}
                  className={`code-input ${codeError ? 'input-error' : ''}`}
                  autoComplete="off" // 禁用自动完成
                />
              </div>
              <button
                type="button" // 明确指定为button类型，防止触发表单提交
                className={`send-code-btn ${countdown > 0 ? 'btn-disabled' : ''}`}
                onClick={handleSendCode}
                disabled={countdown > 0 || isLoading}
              >
                {countdown > 0 ? `${countdown}秒` : '获取验证码'}
              </button>
            </div>
            {codeError === '请输入验证码!' && <div className="error-message">{codeError}</div>}
          </div>
          
          <button
            type="button" // 改为button类型，通过onClick手动处理提交
            className="submit-btn"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? '加载中...' : '继续'}
          </button>
        </form>
        
        <div className="agreement-text">
          首次登录即为注册，即同意 <a href="#" className="agreement-link">服务协议</a>
        </div>
      </div>
    </div>
  );
};

// 添加样式
const styleElement = document.createElement('style');
styleElement.innerHTML = `
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #fff;
  }
  
  .title-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .login-form {
    width: 400px;
    max-width: 90%;
    padding: 20px;
  }
  
  .login-title {
    font-weight: 500;
    font-size: 48px;
    color: ${themeColor};
    display: inline-block;
    line-height: 1.2;
    margin: 0 auto;
    white-space: nowrap; /* 强制不换行 */
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .phone-input-container {
    display: flex;
    align-items: center;
  }
  
  .country-code {
    padding: 0 12px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px 0 0 8px;
    color: ${themeColor};
  }
  
  .phone-input {
    width: 100%;
    height: 50px;
    border-radius: 0 8px 8px 0;
    border: 1px solid #d9d9d9;
    border-left: none;
    padding: 0 12px;
    font-size: 14px;
    outline: none;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  
  .verification-container {
    display: flex;
    gap: 8px;
  }
  
  .code-input-container {
    position: relative;
    flex: auto;
  }
  
  .code-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: ${themeColor};
    z-index: 1;
  }
  
  .code-input {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    padding: 0 12px 0 36px;
    font-size: 14px;
    outline: none;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  
  .send-code-btn {
    height: 50px;
    border-radius: 8px;
    background-color: ${themeColor};
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0 16px;
    font-size: 14px;
    white-space: nowrap;
    transition: all 0.3s;
  }
  
  .btn-disabled {
    background-color: #f0f0f0 !important;
    color: #666 !important;
    cursor: not-allowed;
  }
  
  .submit-btn {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    background-color: ${themeColor};
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s;
  }
  
  .agreement-text {
    text-align: center;
    margin-top: 20px;
    color: #666;
    font-size: 14px;
  }
  
  .agreement-link {
    color: ${themeColor};
    font-weight: 500;
    text-decoration: none;
  }
  
  .error-message {
    color: ${errorColor};
    font-size: 12px;
    margin-top: 5px;
  }
  
  .input-error {
    border-color: ${errorColor} !important;
  }
  
  /* 验证码错误时的抖动动画 */
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
    20%, 40%, 60%, 80% { transform: translateX(5px); }
  }
  
  .shake-animation {
    animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  }
  
  /* 输入框焦点样式 */
  .phone-input:focus,
  .code-input:focus {
    border-color: ${themeColor} !important;
    box-shadow: 0 0 0 2px rgba(26, 26, 26, 0.1);
  }
  
  /* 按钮悬停样式 */
  .send-code-btn:hover:not(:disabled),
  .submit-btn:hover:not(:disabled) {
    background-color: #333;
  }
`;
document.head.appendChild(styleElement);

export default Login; 