import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Input, Typography, Space, Button } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Text } = Typography;

// 自定义样式的上传按钮
export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// 左侧导航栏宽度
export const DRAWER_WIDTH = 250;

// 右侧操作区宽度
export const CONTROL_PANEL_WIDTH = 320;

// API Key输入组件
export const ApiKeyInput = ({ apiKey, setApiKey }) => {
  // 从localStorage加载API Key
  useEffect(() => {
    const savedApiKey = localStorage.getItem('resume_api_key');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
  }, [setApiKey]);

  // 保存API Key到localStorage
  const handleApiKeyChange = (e) => {
    const newApiKey = e.target.value;
    setApiKey(newApiKey);
    localStorage.setItem('resume_api_key', newApiKey);
  };

  return (
    <Space direction="vertical" style={{ width: '30%', marginBottom: '15px' }}>
      <Text type="secondary">API Key</Text>
      <Input
        prefix={<KeyOutlined />}
        placeholder="请输入您的API Key"
        value={apiKey}
        onChange={handleApiKeyChange}
        allowClear
        suffix={
          <Link to="/api-keys">
            <Button type="link" style={{ padding: '0', fontSize: '14px' }}>
              获取
            </Button>
          </Link>
        }
      />
    </Space>
  );
};

// 其他可能的共享组件可以在这里添加 