import React, { useState } from 'react';
import { Tabs } from 'antd';
import ResumeParser from '../components/playground/ResumeParser';
import ResumeGenerator from '../components/playground/ResumeGenerator';
import ResumeChecker from '../components/playground/ResumeChecker';

const { TabPane } = Tabs;

const Playground = () => {
  const [activeTab, setActiveTab] = useState('parser');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div style={{ 
      position: 'fixed',
      top: '104px', // 顶部导航栏的高度
      left: '80px',
      right: '40px',
      bottom: '40px',
      margin: 0,
      padding: 0,
      overflow: 'hidden'
    }}>
      <Tabs 
        activeKey={activeTab} 
        onChange={handleTabChange}
        tabPosition="left"
        style={{ height: '100%' }}
        tabBarStyle={{ 
          width: '160px', 
          borderRight: '1px solid #f0f0f0',
          paddingTop: '15px',
          marginLeft: 0
        }}
        className="playground-tabs"
      >
        <TabPane tab="简历解析" key="parser">
          <ResumeParser />
        </TabPane>
        <TabPane tab="简历评分" key="checker">
          <ResumeChecker />
        </TabPane>
        <TabPane tab="简历生成" key="generator">
          <ResumeGenerator />
        </TabPane>
       
      </Tabs>
    </div>
  );
};

export default Playground;
