import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Card, Tag, Popconfirm, message, Space, Tooltip } from 'antd';
import { PlusOutlined, KeyOutlined, DeleteOutlined, CopyOutlined, EyeOutlined, EyeInvisibleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { apiKeyApi } from '../services/api';
import { useAuth } from '../context/AuthContext';

const { Title, Paragraph, Text } = Typography;

const ApiKeys = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [viewKeyModalVisible, setViewKeyModalVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState(null);
  const [visibleKeys, setVisibleKeys] = useState({});
  const [form] = Form.useForm();
  const { refreshUserInfo } = useAuth();
  const [copiedKeys, setCopiedKeys] = useState({});

  // 获取API密钥列表
  const fetchApiKeys = async () => {
    try {
      setLoading(true);
      const response = await apiKeyApi.getAll();
      setApiKeys(response.data.data.apiKeys);
    } catch (error) {
      message.error('获取API密钥失败');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // 组件加载时获取API密钥
  useEffect(() => {
    fetchApiKeys();
  }, []);

  // 创建API密钥
  const createApiKey = async (values) => {
    try {
      console.log('开始创建API密钥:', values);
      setLoading(true);
      const response = await apiKeyApi.create(values);
      console.log('API密钥创建响应:', response.data);
      
      // 立即更新API密钥列表，添加新创建的密钥
      console.log('准备更新API密钥列表');
      const newApiKey = {
        id: response.data.data.keyId, // 使用keyId而不是id
        name: values.name,
        key: response.data.data.key,
        createdAt: new Date().toISOString(),
        lastUsed: null
      };
      console.log('新的API密钥对象:', newApiKey);
      
      setApiKeys(prevKeys => {
        console.log('更新前的API密钥列表:', prevKeys);
        const updatedKeys = [...prevKeys, newApiKey];
        console.log('更新后的API密钥列表:', updatedKeys);
        return updatedKeys;
      });
      
      // 关闭创建模态框
      console.log('关闭创建模态框');
      setModalVisible(false);
      form.resetFields();
      
      // 显示API密钥 - 移到最后执行，避免被其他UI操作干扰
      console.log('准备显示Modal.success弹窗');
      setTimeout(() => {
        Modal.success({
          title: 'API密钥创建成功',
          content: (
            <div>
              <p>请保存您的API密钥，它只会显示一次：</p>
              <div style={{ 
                background: '#f5f5f5', 
                padding: '10px', 
                borderRadius: '4px',
                wordBreak: 'break-all',
                marginBottom: '10px',
                border: '1px dashed #d9d9d9',
                position: 'relative'
              }}>
                <Text copyable={{ 
                  text: response.data.data.key, 
                  tooltips: ['点击复制', '复制成功'],
                  icon: [<CopyOutlined />, <CheckCircleFilled style={{ color: '#52c41a' }} />]
                }}>
                  {response.data.data.key}
                </Text>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button 
                  type="primary" 
                  icon={<CopyOutlined />}
                  onClick={(e) => {
                    const btn = e.currentTarget;
                    const originalHtml = btn.innerHTML;
                    
                    navigator.clipboard.writeText(response.data.data.key)
                      .then(() => {
                        // 显示成功消息
                        message.success({
                          content: 'API密钥已复制到剪贴板',
                          icon: <CheckCircleFilled style={{ color: '#52c41a' }} />
                        });
                        
                        // 修改按钮文本
                        btn.innerHTML = '<span role="img" aria-label="check-circle" class="anticon anticon-check-circle" style="color: rgb(82, 196, 26);"><svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></span> 复制成功';
                        
                        // 1.5秒后恢复原始状态
                        setTimeout(() => {
                          btn.innerHTML = originalHtml;
                        }, 1500);
                      })
                      .catch(() => {
                        message.warning('无法自动复制API密钥，请手动复制');
                      });
                  }}
                >
                  复制API密钥
                </Button>
                <Paragraph style={{ marginTop: '10px' }}>
                  <Text type="warning">请立即保存此密钥，关闭此窗口后将无法再次查看完整密钥！</Text>
                </Paragraph>
              </div>
            </div>
          ),
          onOk: () => {
            console.log('Modal.success的onOk回调被触发');
            fetchApiKeys();
            refreshUserInfo();
          }
        });
        console.log('Modal.success弹窗已调用');
      }, 100); // 短暂延迟确保其他UI操作完成
      
    } catch (error) {
      console.error('创建API密钥失败:', error);
      message.error(error.response?.data?.message || '创建API密钥失败');
    } finally {
      setLoading(false);
    }
  };

  // 查看API密钥
  const viewApiKey = (key) => {
    setCurrentKey(key);
    setViewKeyModalVisible(true);
  };

  // 复制API密钥
  const copyApiKey = (key, recordId) => {
    navigator.clipboard.writeText(key)
      .then(() => {
        // 显示成功消息
        message.success({
          content: 'API密钥已复制到剪贴板',
          icon: <CheckCircleFilled style={{ color: '#52c41a' }} />
        });
        
        // 临时将按钮图标变为绿色对号
        setCopiedKeys(prev => ({
          ...prev,
          [recordId]: true
        }));
        
        // 1.5秒后恢复原图标
        setTimeout(() => {
          setCopiedKeys(prev => ({
            ...prev,
            [recordId]: false
          }));
        }, 1500);
      })
      .catch(() => {
        message.warning('无法复制API密钥，请手动复制');
      });
  };

  // 格式化API密钥显示
  const formatApiKey = (key, recordId) => {
    if (!key) return '';
    if (visibleKeys[recordId]) return key;
    if (key.length <= 12) return key;
    return `${key.substring(0, 6)}...${key.substring(key.length - 6)}`;
  };

  // 切换API密钥显示/隐藏
  const toggleKeyVisibility = (recordId) => {
    setVisibleKeys(prev => ({
      ...prev,
      [recordId]: !prev[recordId]
    }));
  };

  // 删除API密钥
  const deleteApiKey = async (keyId) => {
    try {
      setLoading(true);
      await apiKeyApi.delete(keyId);
      message.success('API密钥删除成功');
      fetchApiKeys();
      refreshUserInfo();
    } catch (error) {
      message.error(error.response?.data?.message || '删除API密钥失败');
    } finally {
      setLoading(false);
    }
  };

  // 表格列定义
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'API密钥',
      dataIndex: 'key',
      key: 'key',
      render: (text, record) => (
        <Space>
          {record.key ? (
            <>
              <Tag color="blue">{formatApiKey(record.key, record.id)}</Tag>
              
              <Tooltip title="在弹窗中查看">
                <Button 
                  type="text" 
                  icon={<KeyOutlined />} 
                  size="small"
                  onClick={() => viewApiKey(record.key)}
                />
              </Tooltip>
              <Tooltip title="复制密钥">
                <Button 
                  type="text" 
                  icon={copiedKeys[record.id] ? <CheckCircleFilled style={{ color: '#52c41a' }} /> : <CopyOutlined />} 
                  size="small"
                  onClick={() => copyApiKey(record.key, record.id)}
                />
              </Tooltip>
            </>
          ) : (
            <Tag color="blue">密钥已隐藏</Tag>
          )}
        </Space>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: '最后使用时间',
      dataIndex: 'lastUsed',
      key: 'lastUsed',
      render: (text) => text ? new Date(text).toLocaleString() : '从未使用',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="确定要删除这个API密钥吗？"
            description="删除后将无法恢复，使用此密钥的应用将无法访问API。"
            onConfirm={() => deleteApiKey(record.id)}
            okText="删除"
            cancelText="取消"
            okButtonProps={{ danger: true }}
          >
            <Button type="text" danger icon={<DeleteOutlined />}>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>API密钥管理</Title>
      <Paragraph>
        API密钥用于访问CV MCP API服务。您可以创建多个API密钥用于不同的应用或环境。
      </Paragraph>

      <Card style={{ marginBottom: 16 }}>
        <Space style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalVisible(true)}
          >
            创建API密钥
          </Button>
        </Space>

        <Table
          columns={columns}
          dataSource={apiKeys}
          rowKey="id"
          loading={loading}
          pagination={false}
          locale={{ emptyText: '暂无API密钥，点击上方按钮创建' }}
        />
      </Card>

      <Card>
        <Title level={4}>API密钥使用说明</Title>
        <Paragraph>
          <Text strong>如何使用API密钥：</Text>
        </Paragraph>
        <Paragraph>
          在请求头中添加 <Text code>Authorization</Text> 字段，格式为 <Text code>Bearer YOUR_API_KEY</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>示例：</Text>
        </Paragraph>
        <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
          {`curl -X POST https://api.example.com/api/v1/resumes/generate \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"resumeData": {...}, "template": "modern"}'`}
        </pre>
        <Paragraph>
          <Text type="warning">注意：请妥善保管您的API密钥，不要泄露给他人。</Text>
        </Paragraph>
      </Card>

      {/* 创建API密钥的模态框 */}
      <Modal
        title={<><KeyOutlined /> 创建新的API密钥</>}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={createApiKey}
        >
          <Form.Item
            name="name"
            label="API密钥名称"
            rules={[{ required: true, message: '请输入API密钥名称' }]}
          >
            <Input placeholder="例如：生产环境、测试环境" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              创建
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 查看API密钥的模态框 */}
      <Modal
        title="API密钥详情"
        open={viewKeyModalVisible}
        onCancel={() => setViewKeyModalVisible(false)}
        footer={[
          <Button 
            key="copy" 
            type="primary" 
            icon={copiedKeys[currentKey] ? <CheckCircleFilled style={{ color: '#52c41a' }} /> : <CopyOutlined />} 
            onClick={(e) => {
              const btn = e.currentTarget;
              const originalHtml = btn.innerHTML;
              
              copyApiKey(currentKey, currentKey);
              
              // 修改按钮文本和图标
              btn.innerHTML = '<span role="img" aria-label="check-circle" class="anticon anticon-check-circle" style="color: rgb(82, 196, 26);"><svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></span> 复制成功';
              
              // 1.5秒后恢复原始状态
              setTimeout(() => {
                btn.innerHTML = originalHtml;
              }, 1500);
            }}
          >
            复制密钥
          </Button>,
          <Button key="close" onClick={() => setViewKeyModalVisible(false)}>
            关闭
          </Button>
        ]}
      >
        <div style={{ 
          background: '#f5f5f5', 
          padding: '15px', 
          borderRadius: '4px',
          wordBreak: 'break-all',
          border: '1px dashed #d9d9d9',
          marginBottom: '15px'
        }}>
          <Text copyable={{ 
            text: currentKey, 
            tooltips: ['点击复制', '复制成功'],
            icon: [<CopyOutlined />, <CheckCircleFilled style={{ color: '#52c41a' }} />]
          }}>
            {currentKey}
          </Text>
        </div>
        <Paragraph>
          <Text type="warning">注意：请妥善保管您的API密钥，不要泄露给他人。</Text>
        </Paragraph>
      </Modal>
    </div>
  );
};

export default ApiKeys; 