import axios from 'axios';

// 创建axios实例
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' 
    ? 'https://api.nova-cv.com/api/v1'
    : 'http://localhost:5000/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器 - 添加token到请求头
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器 - 处理错误
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // 处理401错误 - 未授权
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// 用户相关API
export const userApi = {
  // 发送验证码
  sendVerificationCode: (phoneData) => api.post('/users/send-code', phoneData),
  
  // 用户登录/注册（通过手机验证码）
  login: (credentials) => api.post('/users/login', credentials),
  
  // 获取当前用户信息
  getCurrentUser: () => api.get('/users/me'),
  
  // 更新用户信息
  updateUser: (userData) => api.put('/users/me', userData),
};

// API密钥相关API
export const apiKeyApi = {
  // 创建API密钥
  create: async (data) => {
    console.log('调用apiKeyApi.create，参数:', data);
    try {
      const response = await api.post('/users/api-keys', data);
      console.log('apiKeyApi.create响应成功:', response.data);
      return response;
    } catch (error) {
      console.error('apiKeyApi.create响应错误:', error);
      throw error;
    }
  },
  
  // 获取所有API密钥
  getAll: () => api.get('/users/api-keys'),
  
  // 删除API密钥
  delete: (keyId) => api.delete(`/users/api-keys/${keyId}`),
};

// 简历相关API
export const resumeApi = {
  // 生成简历
  generate: (data) => api.post('/resumes/generate', data),
  
  // 获取模板列表
  getTemplates: () => api.get('/templates'),
  
  // 解析简历文本
  parseResume: (data) => api.post('/resumes/convert-text', data),
  
  // 分析简历
  analyzeResume: (data) => api.post('/resumes/analyze', data),
};

// 支付相关API
export const paymentApi = {
  // 创建支付记录
  create: async (paymentData) => {
    console.log('调用paymentApi.create，参数:', paymentData);
    try {
      const response = await api.post('/payments', paymentData);
      console.log('paymentApi.create响应成功:', response.data);
      return response;
    } catch (error) {
      console.error('paymentApi.create响应错误:', error);
      throw error;
    }
  },
  
  // 检查订单是否已存在
  check: async (orderId) => {
    console.log('调用paymentApi.check，订单ID:', orderId);
    try {
      const response = await api.get(`/payments/check/${orderId}`);
      console.log('paymentApi.check响应成功:', response.data);
      return response;
    } catch (error) {
      console.error('paymentApi.check响应错误:', error);
      throw error;
    }
  },
  
  // 获取支付状态
  getStatus: (paymentId) => api.get(`/payments/${paymentId}`),
  
  // 获取用户的支付记录
  getUserPayments: () => api.get('/payments'),
};

export default api; 