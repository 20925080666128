import React, { useState, useEffect, useRef } from 'react';
import { 
  Typography, 
  Button, 
  Input, 
  Space, 
  message, 
  Spin, 
  Card,
  Select,
  Alert,
  Image,
  Empty
} from 'antd';
import { 
  FileTextOutlined, 
  SendOutlined,
  DownloadOutlined} from '@ant-design/icons';
import axios from 'axios';
import { ApiKeyInput } from './CommonComponents';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

// 定义卡片和内容区域的固定高度
const CARD_HEIGHT = 'calc(100vh - 370px)';
const CONTENT_HEIGHT = 'calc(100vh - 650px)';

const ResumeGenerator = () => {
  const [jsonResume, setJsonResume] = useState('');
  const [templateName, setTemplateName] = useState('elite');
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const [apiKey, setApiKey] = useState('');
  const [, setContainerHeight] = useState('calc(100vh - 200px)');
  
  const containerRef = useRef(null);

  // 监听窗口大小变化，调整容器高度
  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const viewportHeight = window.innerHeight;
        const containerTop = containerRef.current.getBoundingClientRect().top;
        // 增加底部边距到60px，确保不会超出屏幕
        const newHeight = viewportHeight - containerTop - 60;
        setContainerHeight(`${Math.max(250, newHeight)}px`);
      }
    };

    // 初始化高度
    updateHeight();

    // 监听窗口大小变化
    window.addEventListener('resize', updateHeight);
    
    // 清理函数
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  // 加载模板列表
  useEffect(() => {
    const fetchTemplates = async () => {
      setLoadingTemplates(true);
      try {
        const response = await axios.get('/api/v1/templates', {
          headers: {
            'X-API-KEY': apiKey,
            'Authorization': `Bearer ${apiKey}`
          }
        });
        if (response.data.success) {
          const templatesData = response.data.templates.map(template => ({
            ...template,
            thumbnailUrl: template.previewUrl // 使用previewUrl作为缩略图URL
          }));
          setTemplates(templatesData);
        } else {
          message.error('获取模板列表失败');
        }
      } catch (err) {
        console.error('获取模板列表错误:', err);
        message.error('获取模板列表失败: ' + (err.response?.data?.message || err.message));
      } finally {
        setLoadingTemplates(false);
      }
    };

    if (apiKey) {
      fetchTemplates();
    }
  }, [apiKey]);

  // 处理JSON输入变化
  const handleJsonChange = (e) => {
    setJsonResume(e.target.value);
  };

  // 处理模板选择
  const handleTemplateChange = (value) => {
    setTemplateName(value);
  };

  // 复制模板ID到剪贴板

  // 提交生成请求
  const handleSubmit = async () => {
    if (!jsonResume) {
      message.warning('请输入JSON Resume数据');
      return;
    }

    if (!apiKey) {
      message.warning('请输入API Key');
      return;
    }

    let parsedJson;
    try {
      parsedJson = JSON.parse(jsonResume);
    } catch (err) {
      message.error('JSON格式不正确，请检查');
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await axios.post('/api/v1/resumes/generate', {
        resumeData: parsedJson,
        templateName: templateName,
        options: {}
      }, {
        headers: {
          'X-API-KEY': apiKey,
          'Authorization': `Bearer ${apiKey}`
        }
      });

      if (response.data.success) {
        setResult({
          fileUrl: response.data.data.fileUrl,
          expiresAt: response.data.data.expiresAt,
          thumbnailUrl: response.data.data.thumbnailUrl
        });
        message.success('简历生成成功');
      } else {
        setError(response.data.message || '生成失败，请稍后重试');
      }
    } catch (err) {
      setError(err.response?.data?.message || '服务器错误，请稍后重试');
      message.error('简历生成失败');
    } finally {
      setLoading(false);
    }
  };

  // 清空所有内容
  const clearAll = () => {
    setJsonResume('');
    setResult(null);
    setError(null);
  };

  // 格式化JSON并添加语法高亮
  const formatJsonWithSyntaxHighlighting = (json) => {
    if (!json) return '';
    
    const jsonString = JSON.stringify(json, null, 2);
    
    // 替换键名（带引号的部分）
    const withKeyHighlighting = jsonString.replace(/"([^"]+)":/g, '<span style="color: #9cdcfe;">"$1"</span>:');
    
    // 替换字符串值（键值对中的值部分）
    const withValueHighlighting = withKeyHighlighting.replace(/: "([^"]+)"/g, ': <span style="color: #ce9178;">"$1"</span>');
    
    // 替换数字
    const withNumberHighlighting = withValueHighlighting.replace(/: (\d+)(,?)/g, ': <span style="color: #b5cea8;">$1</span>$2');
    
    // 替换布尔值和null
    const withBooleanHighlighting = withNumberHighlighting
      .replace(/: (true|false)(,?)/g, ': <span style="color: #569cd6;">$1</span>$2')
      .replace(/: (null)(,?)/g, ': <span style="color: #569cd6;">$1</span>$2');
    
    // 添加行号和缩进高亮
    const lines = withBooleanHighlighting.split('\n');
    const withLineNumbers = lines.map((line, index) => {
      // 计算缩进级别（每两个空格为一级）
      // 为缩进添加淡色背景，增强可读性
      const indentedLine = line.replace(/^(\s*)/, `<span style="opacity: 0.5;">$1</span>`);
      
      // 为大括号和中括号添加特殊颜色
      const bracketHighlighted = indentedLine
        .replace(/({|}|\[|])(,?)$/g, '<span style="color: #d4d4d4;">$1</span>$2')
        .replace(/^(\s*)<span style="opacity: 0.5;">(\s*)<\/span>({|}|\[|])(,?)$/g, 
                 '$1<span style="opacity: 0.5;">$2</span><span style="color: #d4d4d4;">$3</span>$4');
      
      return `<div class="code-line" style="display: flex;">
                <span class="line-number" style="color: #858585; min-width: 40px; text-align: right; padding-right: 12px; user-select: none;">${index + 1}</span>
                <span class="line-content">${bracketHighlighted}</span>
              </div>`;
    }).join('');
    
    return <div dangerouslySetInnerHTML={{ __html: withLineNumbers }} />;
  };

  // 格式化JSON
  const formatJson = () => {
    if (!jsonResume) return;
    
    try {
      const parsed = JSON.parse(jsonResume);
      setJsonResume(JSON.stringify(parsed, null, 2));
      message.success('JSON已格式化');
    } catch (err) {
      message.error('JSON格式不正确，无法格式化');
    }
  };

  // 判断是否是有效的JSON字符串
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  // 是否显示格式化的JSON
  const [showFormattedJson, setShowFormattedJson] = useState(false);

  return (
    <div style={{ 
      padding: '10px 20px 30px', 
      height: 'auto', 
      overflow: 'visible',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
    }} ref={containerRef}>
      <Title level={3}>简历生成</Title>
      <Text type="secondary" style={{ marginBottom: '15px', display: 'block' }}>
        使用JSON Resume数据生成精美的简历PDF文件，支持多种模板样式。
      </Text>

      {/* 添加全局滚动条样式和字体样式 */}
      <style>
        {`
          /* 全局字体样式 */
          .resume-generator-container * {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
          }
          
          /* 自定义滚动条样式 - Webkit浏览器 */
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          
          ::-webkit-scrollbar-track {
            background: #f5f5f5;
            border-radius: 4px;
          }
          
          ::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 4px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: #bfbfbf;
          }
          
          /* JSON区域的暗色滚动条 */
          .json-view::-webkit-scrollbar-track {
            background: #1e1e1e;
          }
          
          .json-view::-webkit-scrollbar-thumb {
            background: #6b6b6b;
          }
          
          .json-view::-webkit-scrollbar-thumb:hover {
            background: #8c8c8c;
          }

          /* 代码行样式 */
          .code-line {
            padding: 1px 0;
          }
          
          .code-line:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          
          /* 行号样式 */
          .line-number {
            border-right: 1px solid #333;
            margin-right: 10px;
          }
          
          /* 代码内容样式 */
          .line-content {
            flex: 1;
          }
          
          /* 折叠/展开功能的样式 */
          .collapsible-bracket {
            cursor: pointer;
          }
          
          .collapsible-bracket:hover {
            color: #fff !important;
          }
        `}
      </style>

      {/* API Key输入 */}
      <ApiKeyInput apiKey={apiKey} setApiKey={setApiKey} />

      <div className="resume-generator-container" style={{ display: 'flex', gap: '15px' }}>
        {/* 左侧输入区域 */}
        <div style={{ flex: 1 }}>
          <Card 
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>输入</span>
              </div>
            } 
            bordered={false} 
            style={{ 
              height: CARD_HEIGHT, 
              display: 'flex', 
              flexDirection: 'column', 
              overflow: 'hidden'
            }}
            bodyStyle={{ 
              flex: 1, 
              overflow: 'auto', 
              paddingBottom: '25px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Space direction="vertical" style={{ width: '100%', marginBottom: '15px' }}>
              <Text type="secondary">模板选择</Text>
              <Select
                value={templateName}
                onChange={handleTemplateChange}
                style={{ width: '100%' }}
                loading={loadingTemplates}
                disabled={loadingTemplates}
                optionLabelProp="label"
              >
                {templates.map(template => (
                  <Option 
                    key={template.id} 
                    value={template.id}
                    label={template.name}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {template.thumbnailUrl ? (
                        <img 
                          src={template.thumbnailUrl} 
                          alt={template.name} 
                          style={{ 
                            width: '30px', 
                            height: '30px', 
                            marginRight: '8px',
                            objectFit: 'cover',
                            borderRadius: '2px'
                          }} 
                        />
                      ) : (
                        <FileTextOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                      )}
                      <div>
                        <div>{template.name}</div>
                        <div style={{ fontSize: '12px', color: '#999' }}>{template.id}</div>
                      </div>
                    </div>
                  </Option>
                ))}
                {templates.length === 0 && (
                  <Option value="elite" label="默认模板">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FileTextOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                      <div>
                        <div>默认模板</div>
                        <div style={{ fontSize: '12px', color: '#999' }}>elite</div>
                      </div>
                    </div>
                  </Option>
                )}
              </Select>
            </Space>

            <Space direction="vertical" style={{ width: '100%', flex: 1 }}>
              <Text type="secondary">JSON Resume数据</Text>
              {showFormattedJson && isValidJson(jsonResume) ? (
                <div 
                  className="json-view"
                  style={{ 
                    height: CONTENT_HEIGHT, 
                    overflow: 'auto', 
                    backgroundColor: '#1e1e1e', 
                    borderRadius: '4px', 
                    padding: '8px',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#6b6b6b #1e1e1e'
                  }}
                >
                  <pre style={{ 
                    whiteSpace: 'pre-wrap', 
                    wordBreak: 'break-all', 
                    margin: 0, 
                    fontFamily: 'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
                    fontSize: '14px',
                    color: '#d4d4d4',
                    lineHeight: '1.5'
                  }}>
                    {formatJsonWithSyntaxHighlighting(JSON.parse(jsonResume))}
                  </pre>
                </div>
              ) : (
                <TextArea
                  placeholder="在此粘贴JSON Resume数据..."
                  value={jsonResume}
                  onChange={handleJsonChange}
                  style={{ 
                    fontSize: '14px', 
                    height: CONTENT_HEIGHT,
                    resize: 'none',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#d9d9d9 #f5f5f5',
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
                  }}
                />
              )}
            </Space>

            <div style={{ 
              marginTop: '20px', 
              textAlign: 'right',
              paddingBottom: '5px',
              height: '40px',
              minHeight: '40px'
            }}>
              <Space size="middle">
                <Button onClick={clearAll}>清空</Button>
                <Button onClick={() => {
                  formatJson();
                  if (isValidJson(jsonResume)) {
                    setShowFormattedJson(true);
                  }
                }}>格式化JSON</Button>
                {showFormattedJson && (
                  <Button onClick={() => setShowFormattedJson(false)}>编辑模式</Button>
                )}
                <Button 
                  type="primary" 
                  icon={<SendOutlined />} 
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={!jsonResume}
                >
                  生成简历
                </Button>
              </Space>
            </div>
          </Card>
        </div>

        {/* 右侧结果区域 */}
        <div style={{ flex: 1 }}>
          <Card 
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>生成结果</span>
              </div>
            }
            bordered={false}
            style={{ 
              height: CARD_HEIGHT, 
              display: 'flex', 
              flexDirection: 'column', 
              overflow: 'hidden'
            }}
            bodyStyle={{ 
              flex: 1, 
              overflow: 'auto', 
              paddingBottom: '25px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loading ? (
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: CONTENT_HEIGHT,
                textAlign: 'center',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
              }}>
                <div style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: '#f0f5ff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '16px'
                }}>
                  <Spin size="large" />
                </div>
                <p style={{ 
                  marginTop: '16px', 
                  fontSize: '16px',
                  color: '#1890ff'
                }}>正在生成简历，请稍候...</p>
              </div>
            ) : error ? (
              <Alert
                message="生成失败"
                description={error}
                type="error"
                showIcon
                style={{
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
                }}
              />
            ) : result ? (
              <div style={{ 
                height: CONTENT_HEIGHT, 
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}>
                <div style={{ 
                  width: '100%', 
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {/* 预览内容 */}
                  {result.thumbnailUrl ? (
                    <Image 
                      src={result.thumbnailUrl} 
                      alt="简历预览" 
                      style={{ 
                        maxWidth: '100%', 
                        maxHeight: '500px', 
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                      }}
                    />
                  ) : (
                    <Empty description="无法预览缩略图" />
                  )}
                </div>
                
                <Space>
                  <Button 
                    type="primary" 
                    icon={<DownloadOutlined />} 
                    href={result.fileUrl} 
                    target="_blank"
                  >
                    下载PDF
                  </Button>
                </Space>
                <div style={{ marginTop: '10px', fontSize: '12px', color: '#999' }}>
                  链接有效期至: {new Date(result.expiresAt).toLocaleString()}
                </div>
              </div>
            ) : (
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: CONTENT_HEIGHT,
                textAlign: 'center',
                color: '#999',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
              }}>
                <div style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: '#f5f5f5',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '16px'
                }}>
                  <FileTextOutlined style={{ fontSize: '36px', color: '#bfbfbf' }} />
                </div>
                <p style={{ 
                  fontSize: '16px',
                  marginTop: '16px'
                }}>生成的简历将显示在这里</p>
                <p style={{ 
                  fontSize: '14px',
                  color: '#bfbfbf',
                  maxWidth: '300px',
                  marginTop: '8px'
                }}>
                  请在左侧输入JSON Resume数据，然后点击"生成简历"按钮
                </p>
              </div>
            )}
          </Card>

         
        </div>
      </div>
    </div>
  );
};

export default ResumeGenerator;
