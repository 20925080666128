import React from 'react';
import { Layout } from 'antd';
import Navbar from './Navbar';
import './Navbar.css';

const { Content } = Layout;

const AppLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh', background: '#fff' }}>
      <Navbar />
      <Content
        style={{
          margin: '40px auto',
          padding: '0 80px',
          maxWidth: '1440px',
          width: '100%',
          background: '#fff'
        }}
      >
        <div style={{
          background: '#fff',
          padding: '40px',
          borderRadius: '8px',
          boxShadow: 'none'
        }}>
          {children}
        </div>
      </Content>
    </Layout>
  );
};

export default AppLayout; 