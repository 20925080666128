import React, { useState } from 'react';
import { Typography, Button, Row, Col, Card, Space, Image, Layout, Tooltip, message, Divider } from 'antd';
import { 
  ArrowRightOutlined, 
  FileTextOutlined, 
  SafetyOutlined, 
  RocketOutlined,
  CopyOutlined,
  CheckOutlined,
  GithubOutlined,
  TwitterOutlined,
  LinkedinOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import '../components/Navbar.css';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

// 简历模板数据
const resumeTemplates = [
  { name: '通用', image: `${process.env.PUBLIC_URL}/template/onyx.jpg`, id: 'onyx' },
  { name: '经典', image: `${process.env.PUBLIC_URL}/template/rhyhorn.jpg`, id: 'rhyhorn' },
  { name: '雅蓝', image: `${process.env.PUBLIC_URL}/template/elite.jpg`, id: 'elite' },
  { name: '流线', image: `${process.env.PUBLIC_URL}/template/streamline.jpg`, id: 'streamline' },
  { name: '时间线', image: `${process.env.PUBLIC_URL}/template/timeline.jpg`, id: 'timeline' },
  { name: '极客', image: `${process.env.PUBLIC_URL}/template/azurill.jpg`, id: 'azurill' },
  { name: '素雅', image: `${process.env.PUBLIC_URL}/template/kakuna.jpg`, id: 'kakuna' },
  { name: '和谐', image: `${process.env.PUBLIC_URL}/template/harmony.jpg`, id: 'harmony' },
  { name: '瀑布', image: `${process.env.PUBLIC_URL}/template/cascade.jpg`, id: 'cascade' },
  { name: '单栏', image: `${process.env.PUBLIC_URL}/template/onecolumn.jpg`, id: 'onecolumn' },
  { name: '极简', image: `${process.env.PUBLIC_URL}/template/minimalist.jpg`, id: 'minimalist' },
  { name: '技术卡片', image: `${process.env.PUBLIC_URL}/template/techcard.jpg`, id: 'techcard' },
  { name: '精英', image: `${process.env.PUBLIC_URL}/template/pikachu.jpg`, id: 'pikachu' },
  { name: '商务', image: `${process.env.PUBLIC_URL}/template/gengar.jpg`, id: 'gengar' },
  { name: '专业', image: `${process.env.PUBLIC_URL}/template/ditto.jpg`, id: 'ditto' },
  { name: '简约', image: `${process.env.PUBLIC_URL}/template/bronzor.jpg`, id: 'bronzor' },
  { name: '创意', image: `${process.env.PUBLIC_URL}/template/chikorita.jpg`, id: 'chikorita' },
  { name: '清新', image: `${process.env.PUBLIC_URL}/template/glalie.jpg`, id: 'glalie' },
  { name: '叶子', image: `${process.env.PUBLIC_URL}/template/leafish.jpg`, id: 'leafish' },
  { name: '欧式', image: `${process.env.PUBLIC_URL}/template/nosepass.jpg`, id: 'nosepass' }
];

// 功能特性数据
const features = [
  {
    title: '简历智能检查',
    icon: <SafetyOutlined />,
    description: (
      <>
        <ul style={{ paddingLeft: '20px', marginBottom: '0' }}>
          <br/>基于人力专家的 AI 模型进行简历多维评估
          <br/>专业评分系统，直观展示简历竞争力
          <br/>个性化优化建议，助候选人打造完美简历<br/>
          <br/><strong>适用场景：求职咨询、高校就业指导</strong>
        </ul>
      </>
    ),
    image: `${process.env.PUBLIC_URL}/demo_video/demo_cv_check.jpg`
  },
  {
    title: '简历文本解析',
    icon: <FileTextOutlined />,
    description: (
      <>
        <ul style={{ paddingLeft: '20px', marginBottom: '0' }}>
          <br/>智能识别简历结构，一键转换为标准JSON格式
          <br/>精准提取关键信息，支持多种简历格式
          <br/>数据结构化存储，便于系统集成和分析<br/>
          <br/><strong>适用场景：招聘系统、企业人才库管理</strong>
        </ul>
      </>
    ),
    image: `${process.env.PUBLIC_URL}/demo_video/demo_cv_json.jpg`
  },
  {
    title: '简历模板使用',
    icon: <RocketOutlined />,
    description: (
      <>
        <ul style={{ paddingLeft: '20px', marginBottom: '0' }}>
          <br/>20+精美专业模板，满足不同行业需求
          <br/>一键套用，秒生成PDF格式简历
          <br/>简历内容与样式分离，轻松切换不同风格<br/>
          <br/><strong>适用场景：求职者、招聘系统</strong>
        </ul>
      </>
    ),
    image: `${process.env.PUBLIC_URL}/demo_video/demo_text_cv.mp4`
  }
];

const Home = () => {
  // 跟踪已复制的模板ID
  const [copiedIds, setCopiedIds] = useState({});

  // 复制模板ID到剪贴板
  const copyTemplateId = (id) => {
    navigator.clipboard.writeText(id)
      .then(() => {
        message.success(`已复制模板ID: ${id}`);
        // 设置当前ID为已复制状态
        setCopiedIds(prev => ({ ...prev, [id]: true }));
        // 2秒后恢复为未复制状态
        setTimeout(() => {
          setCopiedIds(prev => ({ ...prev, [id]: false }));
        }, 2000);
      })
      .catch(() => {
        message.error('复制失败，请手动复制');
      });
  };

  return (
    <Layout style={{ minHeight: '100vh', background: '#fff' }}>
      <SEO
        title="AI简历解析与生成"
        description="专注于智能解析简历文本转结构化数据、一键将文本转化为多种精美模板的PDF简历。无需设计经验即可生成专业简历，可直接接入大模型。"
        keywords={[
          'AI简历生成',
          '简历解析',
          'PDF简历',
          '简历模板',
          '智能简历',
          'JSON Resume',
          '简历分析',
          '职业发展'
        ]}
        ogImage="/og-image.jpg"
      />
      {/* 使用通用导航栏组件 */}
      <Navbar />

      <Content style={{ background: '#fff' }}>
        {/* 英雄区域 - 增加了上下间距和两侧边距 */}
        <div style={{ 
          textAlign: 'center', 
          marginTop: 120,
          marginBottom: 120,
          padding: '0 80px'
        }}>
          <Title level={1} style={{ 
            fontSize: '80px', 
            fontWeight: 700, 
            marginBottom: 0,
            letterSpacing: '-0.02em',
            lineHeight: 1.1,
            color: '#000'
          }}>
            NovaCV API
          </Title>
          <Title level={1} style={{ 
            fontSize: '80px', 
            fontWeight: 700, 
            marginTop: 0,
            marginBottom: 40,
            letterSpacing: '-0.02em',
            lineHeight: 1.1,
            color: '#000'
          }}>
            AI 简历解析与生成
          </Title>
          <Paragraph style={{ 
            fontSize: '20px', 
            maxWidth: '800px', 
            margin: '0 auto 48px', 
            color: '#444',
            lineHeight: 1.5
          }}>
            专注于智能解析简历文本转结构化数据、一键将文本转化为多种精美模板的PDF简历。
            无需设计经验即可生成专业简历，可直接接入大模型， 实现大模型与简历处理的无缝衔接。
          </Paragraph>
         
          <Space size="large">
            <Link to="/api-docs">
              <Button 
                type="primary" 
                size="large"
                icon={<ArrowRightOutlined />}
                className="docs-button"
                style={{ 
                  height: '50px', 
                  borderRadius: '6px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  fontSize: '16px',
                  fontWeight: 500,
                  boxShadow: 'none',
                  transition: 'all 0.3s ease'
                }}
              >
                查看API文档
              </Button>
            </Link>
            <Link to="/dashboard">
              <Button 
                size="large"
                className="dashboard-button"
                style={{ 
                  height: '50px', 
                  borderRadius: '6px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  fontSize: '16px',
                  fontWeight: 500,
                  transition: 'all 0.3s ease'
                }}
              >
                进入控制台
              </Button>
            </Link>
          </Space>
        </div>

        {/* 特性部分 - 改为左图右文的布局，增加两侧边距 */}
        <div style={{ marginBottom: 120, padding: '0 80px' }}>
          <Title level={2} style={{ 
            textAlign: 'center', 
            marginBottom: 80, 
            fontWeight: 500,
            fontSize: '48px',
            letterSpacing: '-0.02em'
          }}>
            使用场景
          </Title>
          <Space direction="vertical" size={120} style={{ width: '100%' }}>
            {features.map((feature, index) => (
              <Row gutter={[40, 40]} key={index} align="top">
                {/* 奇数项左图右文，偶数项左文右图 */}
                {index % 2 === 0 ? (
                  <>
                    <Col xs={24} md={12}>
                      <Card 
                        bordered={false} 
                        style={{ 
                          height: '100%', 
                          borderRadius: '12px',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                          overflow: 'hidden'
                        }}
                        bodyStyle={{ padding: 0 }}
                        cover={
                          feature.image.endsWith('.mp4') ? (
                            <video 
                              controls
                              autoPlay
                              loop
                              muted
                              alt={feature.title}
                              src={feature.image}
                              style={{ width: '100%', maxHeight: '100%', display: 'block' }}
                            />
                          ) : (
                            <img 
                              alt={feature.title}
                              src={feature.image}
                              style={{ width: '100%', maxHeight: '100%', display: 'block' }}
                            />
                          )
                        }
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Space align="center" style={{ marginBottom: '16px' }}>
                          <div style={{ 
                            fontSize: '36px', 
                            color: '#1a1a1a', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            {feature.icon}
                          </div>
                          <Title level={3} style={{ margin: 0 }}>{feature.title}</Title>
                        </Space>
                        <Paragraph style={{ color: '#666', fontSize: '18px', lineHeight: '1.8', marginBottom: '24px' }}>
                          {feature.description}
                        </Paragraph>
                        <Link to="/login">
                          <Button 
                            type="primary" 
                            size="large"
                            className="docs-button"
                            style={{ 
                              height: '46px', 
                              borderRadius: '6px',
                              paddingLeft: '24px',
                              paddingRight: '24px',
                              fontSize: '16px',
                              fontWeight: 500
                            }}
                          >
                            立即体验
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={24} md={12}>
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Space align="center" style={{ marginBottom: '16px' }}>
                          <div style={{ 
                            fontSize: '36px', 
                            color: '#1a1a1a', 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                            {feature.icon}
                          </div>
                          <Title level={3} style={{ margin: 0 }}>{feature.title}</Title>
                        </Space>
                        <Paragraph style={{ color: '#666', fontSize: '18px', lineHeight: '1.8', marginBottom: '24px' }}>
                          {feature.description}
                        </Paragraph>
                        <Link to="/login">
                          <Button 
                            type="primary" 
                            size="large"
                            className="docs-button"
                            style={{ 
                              height: '46px', 
                              borderRadius: '6px',
                              paddingLeft: '24px',
                              paddingRight: '24px',
                              fontSize: '16px',
                              fontWeight: 500
                            }}
                          >
                            立即体验
                          </Button>
                        </Link>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <Card 
                        bordered={false} 
                        style={{ 
                          height: '100%', 
                          borderRadius: '12px',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                          overflow: 'hidden'
                        }}
                        bodyStyle={{ padding: 0 }}
                        cover={
                          feature.image.endsWith('.mp4') ? (
                            <video 
                              controls
                              autoPlay
                              loop
                              muted
                              alt={feature.title}
                              src={feature.image}
                              style={{ width: '100%', maxHeight: '100%', display: 'block' }}
                            />
                          ) : (
                            <img 
                              alt={feature.title}
                              src={feature.image}
                              style={{ width: '100%', maxHeight: '100%', display: 'block' }}
                            />
                          )
                        }
                      />
                    </Col>
                  </>
                )}
              </Row>
            ))}
          </Space>
        </div>

        {/* 简历模板展示 - 使用Grid布局确保每行5个模板 */}
        <div style={{ marginBottom: 120, padding: '0 80px' }}>
          <Title level={2} style={{ 
            textAlign: 'center', 
            marginBottom: 24, 
            fontWeight: 500,
            fontSize: '48px',
            letterSpacing: '-0.02em'
          }}>
            精美简历模板
          </Title>
          <Paragraph style={{ 
            textAlign: 'center', 
            marginBottom: 60, 
            color: '#444', 
            fontSize: '18px',
            maxWidth: '800px',
            margin: '0 auto 60px'
          }}>
            我们提供20种精美的简历模板，满足不同行业和职位的需求
          </Paragraph>
          
          {/* 使用CSS Grid布局替代Row/Col */}
          <div 
            className="template-grid"
            style={{ 
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)', // 每行5个模板
              gap: '24px',
              marginBottom: '24px'
            }}
          >
            {resumeTemplates.map((template) => (
              <Card
                key={template.id}
                hoverable
                style={{
                  border: '1px solid rgba(0,0,0,0.15)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                }}
                className="template-card"
                cover={
                  <div style={{ 
                    width: '100%', 
                    paddingTop: '141.4%', // A4纸比例 (1:1.414)
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                    <img 
                      alt={template.name}
                      src={template.image}
                      style={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </div>
                }
              >
                <Card.Meta
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontSize: '14px', marginRight: '8px', fontWeight: 500 }}>{template.name}</span>
                      <Text type="secondary" style={{ fontSize: '12px', marginRight: '4px' }}>
                        ID: {template.id}
                      </Text>
                      <Tooltip title={`复制模板ID: ${template.id}`}>
                        {copiedIds[template.id] ? (
                          <CheckOutlined 
                            style={{ 
                              cursor: 'pointer', 
                              color: '#52c41a', 
                              fontSize: '12px' 
                            }}
                          />
                        ) : (
                          <CopyOutlined 
                            onClick={(e) => {
                              e.stopPropagation();
                              copyTemplateId(template.id);
                            }} 
                            style={{ 
                              cursor: 'pointer', 
                              color: '#666',
                              fontSize: '12px'
                            }}
                          />
                        )}
                      </Tooltip>
                    </div>
                  }
                />
              </Card>
            ))}
          </div>
          
          {/* 添加响应式样式 */}
          <style jsx>{`
            @media (max-width: 1200px) {
              .template-grid {
                grid-template-columns: repeat(4, 1fr);
              }
            }
            @media (max-width: 992px) {
              .template-grid {
                grid-template-columns: repeat(3, 1fr);
              }
            }
            @media (max-width: 768px) {
              .template-grid {
                grid-template-columns: repeat(2, 1fr);
              }
            }
            @media (max-width: 576px) {
              .template-grid {
                grid-template-columns: 1fr;
              }
            }
            
            /* 增强卡片悬停效果 */
            .template-card:hover {
              transform: translateY(-5px);
              box-shadow: 0 8px 20px rgba(0,0,0,0.15);
              border-color: rgba(0,0,0,0.25);
            }
          `}</style>
        </div>
      </Content>

      {/* 使用通用页脚组件 */}
      <Footer />
    </Layout>
  );
};

export default Home;
 