import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/reset.css';
import './components/Navbar.css'; // 导入Navbar样式

// 导入上下文
import { AuthProvider } from './context/AuthContext';

// 导入组件
import AppLayout from './components/AppLayout';
import ProtectedRoute from './components/ProtectedRoute';

// 导入页面
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ApiKeys from './pages/ApiKeys';
import ApiDocs from './pages/ApiDocs';
import Pricing from './pages/Pricing';
import Playground from './pages/Playground';
import Home from './pages/Home';

function App() {
  return (
    <HelmetProvider>
      <ConfigProvider locale={zhCN}>
        <AuthProvider>
          <Router>
            <Routes>
              {/* 公共路由 */}
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              
              {/* 受保护的路由 */}
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/dashboard"
                  element={
                    <AppLayout>
                      <Dashboard />
                    </AppLayout>
                  }
                />
                <Route
                  path="/api-keys"
                  element={
                    <AppLayout>
                      <ApiKeys />
                    </AppLayout>
                  }
                />
                <Route
                  path="/api-docs"
                  element={
                    <AppLayout>
                      <ApiDocs />
                    </AppLayout>
                  }
                />
                <Route
                  path="/pricing"
                  element={
                    <AppLayout>
                      <Pricing />
                    </AppLayout>
                  }
                />
                <Route
                  path="/playground"
                  element={
                    <AppLayout>
                      <Playground />
                    </AppLayout>
                  }
                />
              </Route>
              
              {/* 重定向 */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
