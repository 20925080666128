import React, { useState, useEffect, useRef } from 'react';
import { Typography, Card, Row, Col, Button, List, Tag, Divider, message, Modal, Spin, Progress } from 'antd';
import { CheckOutlined, QuestionCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useAuth } from '../context/AuthContext';
import api, { paymentApi } from '../services/api';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const { Title, Paragraph, Text } = Typography;

// 支付服务配置
const PAYMENT_SERVER = "https://hiretool.cn";
const POLL_INTERVAL = 3000; // 3秒轮询一次
const PAYMENT_TIMEOUT = 15 * 60 * 1000; // 15分钟超时

// 添加一个全局变量来跟踪正在处理的订单
// 这个变量不受React状态更新的影响，可以立即反映订单处理状态
const processingOrders = {};

// API调用次数套餐
const API_PACKAGES = [
  {
    id: 'free',
    title: '免费用户',
    price: '¥0',
    apiCallCredits: 10,
    description: '体验基础功能',
    popular: false,
    buttonText: '当前方案'
  },
  {
    id: 'package1',
    title: '基础套餐',
    price: '¥39.8',
    apiCallCredits: 200,
    description: '适合个人用户和小型项目',
    popular: false,
    buttonText: '立即购买'
  },
  {
    id: 'package2',
    title: '专业套餐',
    price: '¥99.8',
    apiCallCredits: 600,
    description: '适合专业用户和中小型企业',
    popular: true,
    buttonText: '立即购买'
  },
  {
    id: 'package3',
    title: '企业套餐',
    price: '¥199.8',
    apiCallCredits: 1500,
    description: '适合大型企业和高需求用户',
    popular: false,
    buttonText: '立即购买'
  }
];

// 常见问题列表
const FAQ_ITEMS = [
  {
    question: '购买的API调用次数有有效期吗？',
    answer: '购买的API调用次数没有使用期限，您可以在任何时候使用，直到用完为止。'
  },
  {
    question: '支持哪些支付方式？',
    answer: '我们目前支持微信支付。'
  }
];

// 条件日志函数，禁用所有日志输出
const logDebug = (message, data) => {
  // 禁用所有日志输出
  return;
};

const logError = (message, error) => {
  // 禁用所有日志输出
  return;
};

const Pricing = () => {
  const { user, token, isAuthenticated, refreshUserInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  const [apiCallCredits, setApiCallCredits] = useState(0);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [statusCheckInterval, setStatusCheckInterval] = useState(null);
  const [timeLeft, setTimeLeft] = useState(PAYMENT_TIMEOUT);
  const [countdownInterval, setCountdownInterval] = useState(null);
  // 添加一个标志来跟踪支付是否已经处理过
  const [paymentProcessed, setPaymentProcessed] = useState({});

  // 清除所有定时器
  const clearAllTimers = () => {
    if (statusCheckInterval) {
      clearInterval(statusCheckInterval);
      setStatusCheckInterval(null);
    }
    
    if (countdownInterval) {
      clearInterval(countdownInterval);
      setCountdownInterval(null);
    }
  };

  // 重置支付状态
  const resetPaymentState = () => {
    clearAllTimers();
    setPaymentModalVisible(false);
    setSelectedPackage(null);
    setPaymentInfo(null);
    setPaymentStatus(null);
    // 不重置paymentProcessed，因为它需要在会话期间保持
  };

  // 获取套餐信息
  const getPackageInfo = (packageId) => {
    // 如果提供了packageId，直接查找并返回
    if (packageId) {
      const pkg = API_PACKAGES.find(pkg => pkg.id === packageId);
      if (pkg) return pkg;
    }
    
    // 如果没有找到或没有提供packageId，返回null
    return null;
  };

  // 从价格字符串中提取数值
  const extractPriceValue = (priceString) => {
    return parseFloat(priceString.replace('¥', ''));
  };

  // 获取用户API调用次数
  useEffect(() => {
    if (isAuthenticated && token) {
      fetchUserApiCallCredits();
    }
  }, [isAuthenticated, token]);

  // 组件卸载时清除所有定时器
  useEffect(() => {
    return () => {
      clearAllTimers();
    };
  }, []);

  // 组件初始化时重置支付处理状态
  useEffect(() => {
    // 重置支付处理状态
    setPaymentProcessed({});
    // 清除所有定时器
    clearAllTimers();
  }, []);

  // 获取用户API调用次数
  const fetchUserApiCallCredits = async () => {
    try {
      setLoading(true);
      logDebug('开始获取用户API调用次数');
      
      // 直接调用API获取用户信息，而不是依赖refreshUserInfo
      const response = await api.get('/users/me');
      logDebug('获取用户信息响应:', response.data);
      
      if (response && response.data && response.data.data && response.data.data.user) {
        const updatedUser = response.data.data.user;
        const credits = updatedUser.apiCallCredits || 0;
        logDebug(`用户API调用次数: ${credits}`);
        
        setApiCallCredits(credits);
        message.success('API调用次数刷新成功');
      } else {
        logError('获取用户信息响应格式不正确:', response.data);
        message.error('获取用户信息失败');
      }
    } catch (error) {
      logError('获取用户API调用次数失败:', error);
      message.error('获取用户API调用次数失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  // 打开支付模态框
  const openPaymentModal = (packageId) => {
    if (!isAuthenticated) {
      message.warning('请先登录');
      return;
    }
    
    setSelectedPackage(packageId);
    setPaymentModalVisible(true);
    // 直接创建支付订单，不需要选择支付方式
    createPayment(packageId);
  };

  // 关闭支付模态框
  const closePaymentModal = () => {
    // 无论支付状态如何，都允许用户关闭弹窗
    resetPaymentState();
  };

  // 处理支付超时
  const handlePaymentTimeout = () => {
    clearAllTimers();
    
    if (paymentStatus === 'pending') {
      setPaymentStatus('failed');
      message.error('支付超时，请重试');
    }
  };

  // 开始倒计时
  const startCountdown = () => {
    setTimeLeft(PAYMENT_TIMEOUT);
    const countdown = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1000) {
          clearInterval(countdown);
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);
    setCountdownInterval(countdown);
    return countdown;
  };

  // 开始轮询支付状态
  const startPolling = (orderId) => {
    // 先清除可能存在的旧轮询
    if (statusCheckInterval) {
      clearInterval(statusCheckInterval);
      setStatusCheckInterval(null);
    }
    
    const interval = setInterval(() => {
      // 首先检查全局变量，只有当订单状态明确为completed时才停止轮询
      if (processingOrders[orderId] && processingOrders[orderId].status === 'completed') {
        logDebug('轮询检测到订单已完成处理，停止轮询:', orderId);
        clearInterval(interval);
        setStatusCheckInterval(null);
        return;
      }
      
      // 使用函数式更新确保我们总是获取最新的状态
      const isProcessed = paymentProcessed[orderId];
      
      // 只有当订单已处理且状态为成功时，才停止轮询
      if (isProcessed && paymentStatus === 'success') {
        logDebug('轮询检测到订单已处理且状态为成功，停止轮询:', orderId);
        clearInterval(interval);
        setStatusCheckInterval(null);
        return;
      }
      
      // 继续轮询支付状态
      pollPaymentStatus(orderId);
    }, POLL_INTERVAL);
    
    setStatusCheckInterval(interval);
    return interval;
  };

  // 创建支付订单
  const createPayment = async (packageId) => {
    try {
      setLoading(true);
      
      // 获取选中的套餐信息
      const packageInfo = getPackageInfo(packageId);
      if (!packageInfo) {
        message.error('套餐信息不存在');
        return;
      }
      
      // 生成订单ID
      const orderId = `cv_mcp_${Date.now()}`;
      
      // 确保新订单的状态是干净的
      setPaymentStatus(null);
      
      // 在全局变量中保存套餐信息
      processingOrders[orderId] = {
        packageId: packageId,
        apiCallCredits: packageInfo.apiCallCredits,
        status: 'created',
        createdAt: Date.now()
      };
      
      logDebug('创建订单并保存套餐信息到全局变量:', {
        orderId,
        packageInfo: processingOrders[orderId]
      });
      
      // 调用hiretool支付服务创建订单
      const response = await fetch(`${PAYMENT_SERVER}/create-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          orderId,
          amount: extractPriceValue(packageInfo.price),
          description: `NovaCV-API ${packageInfo.title} - ${packageInfo.apiCallCredits}次API调用`,
        }),
      });
      
      const data = await response.json();
      logDebug('支付服务返回数据:', data);
      
      if (data.success && data.qrcode) {
        // 保存支付信息
        const paymentData = {
          orderId,
          packageId, // 确保保存packageId
          amount: extractPriceValue(packageInfo.price),
          apiCallCredits: packageInfo.apiCallCredits,
          qrCode: data.qrcode, // 确保使用API返回的qrcode
          createdAt: new Date().toISOString()
        };
        
        logDebug('保存的支付信息:', paymentData);
        
        setPaymentInfo(paymentData);
        setPaymentStatus('pending');
        
        // 开始倒计时
        startCountdown();
        
        // 开始轮询支付状态
        startPolling(orderId);
        
        // 设置支付超时
        setTimeout(handlePaymentTimeout, PAYMENT_TIMEOUT);
      } else {
        message.error('获取支付二维码失败');
      }
    } catch (error) {
      logError('创建支付订单失败:', error);
      message.error('创建支付订单失败');
    } finally {
      setLoading(false);
    }
  };

  // 轮询支付状态
  const pollPaymentStatus = async (orderId) => {
    try {
      // 首先检查全局变量，如果订单已经完成处理，直接返回
      if (processingOrders[orderId] && processingOrders[orderId].status === 'completed') {
        logDebug('该订单已完成处理，跳过:', orderId);
        return;
      }
      
      // 如果该订单已经处理过且支付状态为成功，直接返回
      if (paymentProcessed[orderId] && paymentStatus === 'success') {
        logDebug('该订单已处理且状态为成功，跳过:', orderId);
        return;
      }
      
      logDebug('轮询支付状态，订单ID:', orderId);
      
      const response = await fetch(`${PAYMENT_SERVER}/check-payment-status/${orderId}`, {
        headers: {
          Accept: "application/json",
        },
      });
      
      const data = await response.json();
      logDebug('支付状态检查结果:', data);
      
      if (data.success && (data.paid || data.trade_state === "SUCCESS")) {
        logDebug('支付成功，准备完成支付流程');
        
        // 立即在全局变量中标记该订单为正在处理
        if (processingOrders[orderId]) {
          processingOrders[orderId].status = 'processing';
          logDebug('全局变量更新订单状态为处理中:', orderId, processingOrders[orderId]);
        } else {
          processingOrders[orderId] = { 
            status: 'processing',
            packageId: selectedPackage, // 使用当前选中的套餐作为备选
            createdAt: Date.now()
          };
          logDebug('全局变量创建订单状态为处理中:', orderId);
        }
        
        // 立即标记该订单为已处理
        setPaymentProcessed(prev => {
          if (prev[orderId]) return prev;
          return { ...prev, [orderId]: true };
        });
        
        // 设置支付状态为成功，确保UI能够显示支付成功
        setPaymentStatus('success');
        
        // 支付成功，调用后端API创建支付记录并增加API调用次数
        await completePayment(orderId);
        
        // 确保在支付成功后自动关闭模态框
        setTimeout(() => {
          closePaymentModal();
        }, 2000);
      } else if (data.error) {
        logError('支付状态检查返回错误:', data.error);
      } else {
        // 如果没有明确的成功或错误状态，继续轮询
        logDebug('支付状态未确定，继续轮询');
      }
    } catch (error) {
      logError('检查支付状态失败:', error);
      // 不要在这里停止轮询，让它继续尝试
    }
  };

  // 完成支付，创建支付记录并增加API调用次数
  const completePayment = async (orderId) => {
    try {
      // 再次检查全局变量，如果订单已经处理过，直接返回
      // 这个检查是即时的，不依赖于React状态
      if (processingOrders[orderId] && processingOrders[orderId].status === 'completed') {
        logDebug('全局变量显示订单已完成处理，跳过:', orderId);
        return;
      }
      
      // 再次检查该订单是否已经处理过，双重保险
      // 使用本地变量存储当前处理状态，避免状态更新延迟导致的问题
      const isProcessed = paymentProcessed[orderId];
      if (isProcessed) {
        logDebug('该订单已经处理过，跳过重复处理:', orderId);
        // 标记为已完成
        if (processingOrders[orderId]) {
          processingOrders[orderId].status = 'completed';
        } else {
          processingOrders[orderId] = { status: 'completed' };
        }
        return;
      }
      
      // 立即再次标记为已处理，确保不会重复处理
      setPaymentProcessed(prev => ({ ...prev, [orderId]: true }));
      
      // 清除轮询和倒计时
      clearAllTimers();
      
      // 不再重复设置支付状态，因为已经在pollPaymentStatus中设置了
      // setPaymentStatus('success');
      message.success('支付成功！');
      
      // 从全局变量中获取套餐信息
      let packageId, apiCallCredits;
      if (processingOrders[orderId] && processingOrders[orderId].packageId) {
        packageId = processingOrders[orderId].packageId;
        apiCallCredits = processingOrders[orderId].apiCallCredits;
        logDebug('从全局变量获取套餐信息:', { packageId, apiCallCredits });
      } else {
        // 如果全局变量中没有套餐信息，使用selectedPackage作为备选
        packageId = selectedPackage;
        logDebug('全局变量中无套餐信息，使用selectedPackage:', packageId);
      }
      
      // 如果仍然无法获取packageId，使用默认packageId
      if (!packageId && orderId) {
        // 默认使用package1作为备选方案
        packageId = 'package1';
        logDebug('使用默认packageId:', packageId);
      }
      
      // 获取选中的套餐信息
      const packageInfo = getPackageInfo(packageId);
      
      // 如果无法获取套餐信息，直接刷新用户API调用次数
      if (!packageInfo) {
        logError('无法获取套餐信息，packageId:', packageId);
        // 尝试刷新用户API调用次数
        await fetchUserApiCallCredits();
        
        // 标记为已完成
        if (processingOrders[orderId]) {
          processingOrders[orderId].status = 'completed';
        } else {
          processingOrders[orderId] = { status: 'completed' };
        }
        
        // 关闭支付模态框
        setTimeout(() => {
          closePaymentModal();
        }, 2000);
        return;
      }
      
      // 使用从packageInfo获取的apiCallCredits，确保使用正确的值
      apiCallCredits = apiCallCredits || packageInfo.apiCallCredits;
      
      // 记录支付信息，用于调试
      logDebug('准备创建支付记录，参数:', {
        orderId,
        packageId,
        paymentMethod: 'wechat',
        amount: extractPriceValue(packageInfo.price),
        apiCallCredits
      });
      
      // 尝试检查该订单是否已经在后端处理过
      let orderExists = false;
      try {
        const checkResponse = await paymentApi.check(orderId);
        if (checkResponse.data && checkResponse.data.success && checkResponse.data.exists) {
          logDebug('该订单已在后端处理过:', orderId);
          orderExists = true;
        }
      } catch (checkError) {
        // 如果检查失败（可能是后端没有实现check接口），继续尝试创建支付记录
        logError('检查订单是否存在失败:', checkError);
      }
      
      // 如果订单已存在，直接刷新用户API调用次数并返回
      if (orderExists) {
        await fetchUserApiCallCredits();
        
        // 标记为已完成
        if (processingOrders[orderId]) {
          processingOrders[orderId].status = 'completed';
        } else {
          processingOrders[orderId] = { status: 'completed' };
        }
        
        setTimeout(() => {
          closePaymentModal();
        }, 2000);
        return;
      }
      
      // 如果订单不存在，调用后端API创建支付记录
      try {
        // 确保传递正确的packageId和apiCallCredits
        const response = await paymentApi.create({
          orderId,
          packageId, // 确保传递正确的packageId
          paymentMethod: 'wechat',
          amount: extractPriceValue(packageInfo.price),
          apiCallCredits // 使用从全局变量或packageInfo获取的apiCallCredits
        });
        
        logDebug('支付记录创建响应:', response.data);
        
        if (response.data.success) {
          // 刷新用户API调用次数
          await fetchUserApiCallCredits();
        } else {
          logError('支付记录创建失败:', response.data);
          message.error('支付成功，但订阅激活失败，请联系客服');
        }
      } catch (createError) {
        // 如果创建失败，可能是因为订单已存在（并发情况）
        logError('创建支付记录失败:', createError);
        
        // 尝试刷新用户API调用次数
        try {
          await fetchUserApiCallCredits();
        } catch (refreshError) {
          logError('刷新API调用次数失败:', refreshError);
        }
        
        // 如果是因为订单已存在而失败，不显示错误消息
        if (createError.response && createError.response.data && createError.response.data.error === 'ORDER_EXISTS') {
          logDebug('订单已存在，跳过错误提示');
        } else {
          message.error('支付成功，但订阅激活失败，请联系客服');
        }
      }
      
      // 标记为已完成
      if (processingOrders[orderId]) {
        processingOrders[orderId].status = 'completed';
      } else {
        processingOrders[orderId] = { status: 'completed' };
      }
      
      // 无论成功失败，都关闭支付模态框
      setTimeout(() => {
        closePaymentModal();
      }, 2000);
    } catch (error) {
      logError('完成支付失败:', error);
      // 尝试刷新用户API调用次数，即使创建支付记录失败
      try {
        await fetchUserApiCallCredits();
      } catch (refreshError) {
        logError('刷新API调用次数失败:', refreshError);
      }
      
      // 标记为已完成，即使出错也不再重试
      if (processingOrders[orderId]) {
        processingOrders[orderId].status = 'completed';
      } else {
        processingOrders[orderId] = { status: 'completed' };
      }
      
      message.error('支付成功，但订阅激活失败，请联系客服');
      
      // 关闭支付模态框
      setTimeout(() => {
        closePaymentModal();
      }, 2000);
    }
  };

  // 格式化倒计时时间
  const formatTimeLeft = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // 渲染支付模态框内容
  const renderPaymentModalContent = () => {
    // 获取当前选中的套餐信息
    const selectedPackageInfo = getPackageInfo(selectedPackage);
    
    // 如果支付状态为成功，显示支付成功内容
    if (paymentStatus === 'success') {
      // 尝试获取正确的API调用次数
      let apiCallCreditsToShow = 0;
      let packageTitle = '';
      
      // 1. 首先尝试从paymentInfo中获取
      if (paymentInfo && paymentInfo.apiCallCredits) {
        apiCallCreditsToShow = paymentInfo.apiCallCredits;
        logDebug('使用paymentInfo中的apiCallCredits:', apiCallCreditsToShow);
        
        // 尝试获取套餐标题
        if (paymentInfo.packageId) {
          const pkgInfo = getPackageInfo(paymentInfo.packageId);
          if (pkgInfo) {
            packageTitle = pkgInfo.title;
          }
        }
      }
      
      // 2. 如果有orderId，尝试从全局变量中获取
      if (paymentInfo && paymentInfo.orderId && processingOrders[paymentInfo.orderId]) {
        const orderInfo = processingOrders[paymentInfo.orderId];
        if (orderInfo.apiCallCredits) {
          apiCallCreditsToShow = orderInfo.apiCallCredits;
          logDebug('使用全局变量中的apiCallCredits:', apiCallCreditsToShow);
          
          // 尝试获取套餐标题
          if (orderInfo.packageId) {
            const pkgInfo = getPackageInfo(orderInfo.packageId);
            if (pkgInfo) {
              packageTitle = pkgInfo.title;
            }
          }
        }
      }
      
      // 3. 最后才使用selectedPackageInfo作为备选
      if (apiCallCreditsToShow === 0 && selectedPackageInfo) {
        apiCallCreditsToShow = selectedPackageInfo.apiCallCredits;
        packageTitle = selectedPackageInfo.title;
        logDebug('使用selectedPackageInfo中的apiCallCredits:', apiCallCreditsToShow);
      }
      
      return (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <CheckCircleFilled style={{ fontSize: 70, color: '#52c41a', marginBottom: 20 }} />
          <Title level={3}>支付成功</Title>
          <Paragraph>
            您已成功购买 <Text strong>{packageTitle}</Text> 套餐
          </Paragraph>
          <Paragraph>
            获得 <Text strong>{apiCallCreditsToShow}</Text> 次API调用额度
          </Paragraph>
          <Paragraph type="secondary">
            页面将在2秒后自动关闭
          </Paragraph>
        </div>
      );
    }
    
    // 如果支付状态为失败，显示支付失败内容
    if (paymentStatus === 'failed') {
      return (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <div style={{ fontSize: 70, color: '#ff4d4f', marginBottom: 20 }}>✗</div>
          <Title level={3}>支付失败</Title>
          <Paragraph type="secondary">
            请重新尝试支付
          </Paragraph>
          <Button type="primary" onClick={() => createPayment(selectedPackage)}>
            重新支付
          </Button>
        </div>
      );
    }
    
    if (paymentInfo && paymentStatus === 'pending') {
      logDebug('渲染二维码时的支付信息:', paymentInfo);
      
      // 获取套餐信息，优先使用paymentInfo中的packageId
      let packageToShow = selectedPackageInfo;
      if (paymentInfo.packageId) {
        const pkgInfo = getPackageInfo(paymentInfo.packageId);
        if (pkgInfo) {
          packageToShow = pkgInfo;
        }
      }
      
      // 如果仍然没有套餐信息，显示加载中
      if (!packageToShow) {
        return <Paragraph>加载中...</Paragraph>;
      }
      
      return (
        <div style={{ textAlign: 'left' }}>
          <div style={{ padding: '0 15px' }}>
            <Paragraph style={{ marginBottom: '5px' }}>
              <Text>订单名称：</Text> NovaCV-API {packageToShow.title}
            </Paragraph>
            <Paragraph style={{ marginBottom: '5px' }}>
              <Text>商品价格：</Text> {packageToShow.price}
            </Paragraph>
          </div>
          
          <Divider style={{ margin: '10px 0' }} />
          
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            {/* 添加QR码加载状态 */}
            <div style={{ position: 'relative', width: 200, height: 200, margin: '0 auto' }}>
              <img 
                src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(paymentInfo.qrCode)}`}
                alt="支付二维码" 
                style={{ width: 200, height: 200 }}
                onLoad={(e) => {
                  e.target.style.opacity = 1;
                  // 找到父元素下的loading元素并隐藏
                  const loadingEl = e.target.parentNode.querySelector('.qr-code-loading');
                  if (loadingEl) {
                    loadingEl.style.display = 'none';
                  }
                }}
                onError={(e) => {
                  e.target.style.opacity = 0;
                }}
                className="qr-code-image"
              />
              <div className="qr-code-loading" style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                background: 'rgba(255, 255, 255, 0.7)',
                zIndex: 1
              }}>
                <Spin />
              </div>
            </div>
          </div>
          
          <div style={{ color: '#07c160', margin: '15px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg viewBox="0 0 1024 1024" width="20" height="20" style={{ marginRight: '5px' }}>
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" fill="#07c160"></path>
              <path d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm193.4 225.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.3 0 19.9 5 25.9 13.2l71.2 98.8 157.2-218c6-8.4 15.7-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.4 12.7z" fill="#07c160"></path>
            </svg>
            请使用微信扫码支付
          </div>
          
          <div style={{ textAlign: 'center' }}>
            <Paragraph type="secondary" style={{ marginTop: '10px' }}>
              等待支付中 {formatTimeLeft(timeLeft)}秒
            </Paragraph>
          </div>
        </div>
      );
    }
    
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
        <Paragraph style={{ marginTop: 20 }}>正在生成支付二维码，请稍候...</Paragraph>
      </div>
    );
  };

  return (
    <>
      <SEO
        title="价格方案"
        description="选择最适合您需求的API调用套餐。我们提供灵活的定价方案，从免费体验到企业级解决方案。"
        keywords={[
          'API价格',
          '简历服务',
          '企业套餐',
          '专业套餐',
          '基础套餐',
          'API调用',
          '简历分析价格'
        ]}
      />
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <Title level={1} style={{ fontSize: '36px', fontWeight: 'bold' }}>API调用次数</Title>
          <Paragraph style={{ fontSize: '16px', color: '#666' }}>
            选择适合您需求的API调用次数套餐。注册用户默认赠送10次免费调用额度。
          </Paragraph>
        </div>
        
        {isAuthenticated ? (
          <Card style={{ marginBottom: 32, borderRadius: '8px' }}>
            <Spin spinning={loading}>
              <Row align="middle" gutter={16}>
                <Col xs={24} md={12}>
                  <Title level={3} style={{ margin: 0 }}>当前可用API调用次数</Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    您当前拥有 <Text strong style={{ fontSize: 24, color: '#1890ff' }}>{apiCallCredits}</Text> 次API调用额度
                  </Paragraph>
                </Col>
                <Col xs={24} md={12} style={{ textAlign: 'right' }}>
                  <Button 
                    type="primary" 
                    size="large"
                    onClick={() => fetchUserApiCallCredits()}
                    loading={loading}
                    style={{ background: '#000', borderColor: '#000' }}
                  >
                    刷新额度
                  </Button>
                </Col>
              </Row>
            </Spin>
          </Card>
        ) : (
          <Card style={{ marginBottom: 32, borderRadius: '8px' }}>
            <Paragraph>
              请<a href="/login">登录</a>查看您的API调用次数
            </Paragraph>
          </Card>
        )}
        
        <Row gutter={[24, 24]} justify="center">
          {API_PACKAGES.map((pkg) => (
            pkg.id !== 'free' && (
              <Col xs={24} sm={12} md={8} key={pkg.id}>
                <Card
                  hoverable
                  style={{
                    height: '100%',
                    borderColor: pkg.popular ? '#000' : undefined,
                    borderWidth: pkg.popular ? '2px' : '1px',
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                  bodyStyle={{ padding: '24px 16px' }}
                >
                  {pkg.popular && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        background: '#000',
                        color: 'white',
                        padding: '4px 8px',
                        fontSize: '12px',
                      }}
                    >
                      不二之选
                    </div>
                  )}
                  <div style={{ textAlign: 'center' }}>
                    <Title level={3} style={{ marginBottom: '8px', fontSize: '20px' }}>{pkg.title}</Title>
                    <div style={{ margin: '16px 0' }}>
                      <Text style={{ fontSize: '32px', fontWeight: 'bold' }}>{pkg.price}</Text>
                      <div style={{ marginTop: '5px' }}>
                        <Text>{pkg.apiCallCredits}次API调用</Text>
                      </div>
                    </div>
                    <Paragraph type="secondary">{pkg.description}</Paragraph>
                    <Button
                      type={pkg.popular ? 'primary' : 'default'}
                      size="large"
                      block
                      style={{ 
                        marginBottom: '20px',
                        background: pkg.popular ? '#000' : undefined,
                        borderColor: pkg.popular ? '#000' : undefined,
                      }}
                      onClick={() => openPaymentModal(pkg.id)}
                    >
                      {pkg.buttonText}
                    </Button>
                  </div>
                  <Divider style={{ margin: '12px 0' }} />
                  <div style={{ textAlign: 'center', marginBottom: 16 }}>
                    <Paragraph>适用于所有API接口</Paragraph>
                  </div>
                </Card>
              </Col>
            )
          ))}
        </Row>

        <Card style={{ marginTop: 48, borderRadius: '8px' }}>
          <Title level={3}>常见问题</Title>
          <List
            itemLayout="vertical"
            dataSource={FAQ_ITEMS}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<QuestionCircleOutlined />}
                  title={<Text strong>{item.question}</Text>}
                  description={item.answer}
                />
              </List.Item>
            )}
          />
        </Card>

        <Modal
          title="购买API调用次数"
          open={paymentModalVisible}
          onCancel={closePaymentModal}
          footer={null}
          width={400}
          maskClosable={false}
          closable={true}
          destroyOnClose={true}
        >
          <Spin spinning={loading && !paymentInfo}>
            {renderPaymentModalContent()}
          </Spin>
        </Modal>
        
        {/* 添加QR码加载样式 */}
        <style jsx="true">{`
          .qr-code-image {
            opacity: 0;
            transition: opacity 0.3s;
          }
        `}</style>
      </div>
      <Footer />
    </>
  );
};

export default Pricing; 