import React from 'react';
import { Layout, Menu, Avatar, Dropdown, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '../context/AuthContext';

const { Header } = Layout;

const Navbar = () => {
  const { user, logout } = useAuth();
  const location = useLocation();

  // 用户下拉菜单
  const userMenu = (
    <Menu>
      <Menu.Item key="profile" icon={<UserOutlined />} disabled>
        {user?.phone ? `${user.phone.slice(0, 3)}****${user.phone.slice(-4)}` : '未知用户'}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ 
      padding: '0 80px',
      background: '#fff', 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      height: '80px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.02)',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      borderBottom: '1px solid rgba(0,0,0,0.03)'
    }}>
      <div>
        <Link to="/">
          <img 
            src="/logo.svg" 
            alt="CV MCP Logo" 
            style={{ height: '20px' }} 
          />
        </Link>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="nav-links" style={{ display: 'flex', gap: '32px' }}>
          
          <Link 
            to="/api-docs" 
            className={location.pathname === '/api-docs' ? 'nav-link active' : 'nav-link'}
          >
            API文档
          </Link>
          <Link 
            to="/pricing" 
            className={location.pathname === '/pricing' ? 'nav-link active' : 'nav-link'}
          >
            API价格
          </Link>
          <Link 
            to="/api-keys" 
            className={location.pathname === '/api-keys' ? 'nav-link active' : 'nav-link'}
          >
            API密钥
          </Link>
          <Link 
            to="/playground" 
            className={location.pathname === '/playground' ? 'nav-link active' : 'nav-link'}
          >
            Playground
          </Link>
        </div>

        <div style={{ marginLeft: '40px' }}>
          {user ? (
            <Dropdown overlay={userMenu} placement="bottomRight">
              <Avatar 
                icon={<UserOutlined />} 
                style={{ 
                  backgroundColor: '#1a1a1a',
                  cursor: 'pointer'
                }} 
              />
            </Dropdown>
          ) : (
            <Link to="/login">
              <Button 
                type="primary" 
                style={{ 
                  backgroundColor: '#1a1a1a', 
                  borderColor: '#1a1a1a',
                  borderRadius: '6px',
                  height: '36px',
                  padding: '0 24px',
                  fontSize: '16px',
                  fontWeight: 500,
                  boxShadow: 'none',
                  transition: 'all 0.3s ease'
                }}
                className="login-button"
              >
                登录
              </Button>
            </Link>
          )}
        </div>
      </div>
    </Header>
  );
};

export default Navbar; 