import React from 'react';
import { Row, Col, Card, Statistic, Button, Typography, Space } from 'antd';
import { KeyOutlined, FileOutlined, ApiOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import SEO from '../components/SEO';

const { Title, Paragraph } = Typography;

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <div>
      <SEO
        title="控制台"
        description="管理您的API密钥、查看使用统计、访问API文档等。"
        keywords={[
          '控制台',
          'API管理',
          '使用统计',
          'API文档',
          '开发者工具'
        ]}
        noindex={true}
      />
      <div style={{ textAlign: 'center', marginBottom: '60px' }}>
        <Title level={1} style={{ fontSize: '48px', fontWeight: 500, marginBottom: '24px' }}>
          简历分析系统
        </Title>
        <Paragraph style={{ fontSize: '18px', maxWidth: '700px', margin: '0 auto', color: '#666' }}>
          我们提供可靠、可解释、可控制的简历分析系统。
        </Paragraph>
      </div>

      <Row gutter={[32, 32]}>
        <Col xs={24} md={8}>
          <Card 
            bordered={false} 
            style={{ 
              height: '100%', 
              borderRadius: '12px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
              transition: 'all 0.3s ease'
            }}
            hoverable
          >
            <div style={{ textAlign: 'center' }}>
              <KeyOutlined style={{ fontSize: '32px', color: '#1a1a1a', marginBottom: '16px' }} />
              <Title level={3} style={{ marginBottom: '16px' }}>API密钥</Title>
              <Paragraph style={{ marginBottom: '24px', color: '#666' }}>
                管理您的API密钥，用于访问简历分析服务。
              </Paragraph>
              <Link to="/api-keys">
                <Button 
                  type="primary" 
                  size="large"
                  style={{ 
                    height: '44px', 
                    borderRadius: '22px',
                    paddingLeft: '24px',
                    paddingRight: '24px'
                  }}
                >
                  管理API密钥
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
        
        <Col xs={24} md={8}>
          <Card 
            bordered={false} 
            style={{ 
              height: '100%', 
              borderRadius: '12px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
              transition: 'all 0.3s ease'
            }}
            hoverable
          >
            <div style={{ textAlign: 'center' }}>
              <FileOutlined style={{ fontSize: '32px', color: '#1a1a1a', marginBottom: '16px' }} />
              <Title level={3} style={{ marginBottom: '16px' }}>API文档</Title>
              <Paragraph style={{ marginBottom: '24px', color: '#666' }}>
                查看详细的API文档，了解如何集成我们的服务。
              </Paragraph>
              <Link to="/api-docs">
                <Button 
                  type="primary" 
                  size="large"
                  style={{ 
                    height: '44px', 
                    borderRadius: '22px',
                    paddingLeft: '24px',
                    paddingRight: '24px'
                  }}
                >
                  查看文档
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
        
        <Col xs={24} md={8}>
          <Card 
            bordered={false} 
            style={{ 
              height: '100%', 
              borderRadius: '12px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
              transition: 'all 0.3s ease'
            }}
            hoverable
          >
            <div style={{ textAlign: 'center' }}>
              <ApiOutlined style={{ fontSize: '32px', color: '#1a1a1a', marginBottom: '16px' }} />
              <Title level={3} style={{ marginBottom: '16px' }}>API价格</Title>
              <Paragraph style={{ marginBottom: '24px', color: '#666' }}>
                了解我们的价格方案，选择最适合您需求的套餐。
              </Paragraph>
              <Link to="/pricing">
                <Button 
                  type="primary" 
                  size="large"
                  style={{ 
                    height: '44px', 
                    borderRadius: '22px',
                    paddingLeft: '24px',
                    paddingRight: '24px'
                  }}
                >
                  查看价格
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard; 