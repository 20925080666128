import React from 'react';
import { Row, Col, Space, Layout, Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import './Navbar.css';

const { Footer: AntFooter } = Layout;
const { Text, Paragraph } = Typography;

const Footer = () => {
  return (
    <AntFooter style={{ 
      background: '#fff', 
      borderTop: '1px solid #f0f0f0',
      padding: '40px 80px'
    }}>
      <Row gutter={[40, 40]} align="middle" justify="space-between">
        <Col xs={16} sm={16} md={16} lg={16}>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            {/* Using the logo image from public directory */}
            <img 
              src="/logo.svg" 
              alt="NovaCV Logo" 
              className="footer-logo" 
              style={{ height: '40px' }} 
            />
            <Text style={{ color: '#666', display: 'block' }}>
              NovaCV 让简历变简单，让求职变简单
            </Text>
            <Text style={{ color: '#888', fontSize: '14px' }}>
              © 2025 NovaCV. 保留所有权利。
            </Text>
          </Space>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
          <Space direction="vertical" size={16} align="center">
            <Text style={{ color: '#666', fontSize: '14px' }}>联系开发者</Text>
            <Tooltip title="扫码添加客服微信">
              <img 
                src="/wechat.jpg" 
                alt="WeChat QR Code" 
                style={{ 
                  width: '120px',
                  height: '120px',
                  borderRadius: '8px',
                  border: '1px solid #f0f0f0'
                }} 
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer; 